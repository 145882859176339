/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#01  Fonts and Other Styles Import
#02  Theme Reset Style
#03  Others
-----------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800&family=Nunito+Sans:wght@400;600;700;800;900&display=swap");
@import url("fonts/ionicons/css/ionicons.css");
@import url("fonts/font-awesome/css/font-awesome.css");
@import url("bootstrap.min.css");

:root {
  --primary-color: #00326f;
  --second-color: #ffa903;
  --body-color: #272d2d;
  --text-heading-color: #00234b;
  --heading-color: #173823;
  --testimonial-below-color: #979a9f;
  --testimonial-paragraph-color: #656a7c;
  --white-color: #ffffff;
  --black-color: #000000;
  --dark-color: #222222;
}

.theme-color {
  color: var(--second-color);
}

.overlay {
  position: absolute;
  /* Sit on top of the page content */
  opacity: 0;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0f1530;
  /* Black background with opacity */
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
  transition: all 0.5s;
}

html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  line-height: 30px;
  color: var(--body-color);
  overflow-x: hidden;
  webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main_wrapper {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  margin: 0px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

p {
  margin-bottom: 0px;
  font-family: "Mulish", sans-serif;
}

a {
  color: var(--second-color);
  text-decoration: none;
  transition: all 0.5s ease;
  outline: none;
}

a:hover {
  color: var(--second-color);
  text-decoration: none;
  transition: all 0.5s ease;
  outline: none;
}

.container {
  position: relative;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
    padding: 0px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}

.clearfix {
  clear: both;
}

.divider {
  margin: 30px 0px;
  display: inline-block;
}

table {
  margin-bottom: 10px;
  border: 1px solid #eee;
}

table td,
table th {
  padding: 3px 10px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  text-align: center;
}

img {
  max-width: 100%;
}

::-webkit-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
}

::-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
}

:-ms-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
}

:-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
}

:placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: var(--body-color);
}

.section {
  position: relative;
  padding-top: 110px;
  padding-bottom: 110px;
}

@media (max-width: 394px) {
  .section {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section_sm {
  position: relative;
  padding-top: 80px;
  padding-bottom: 80px;
}

.pd_tp_110 {
  padding-top: 110px;
}

.pd_btom_110 {
  padding-bottom: 110px;
}

.pd_btom_80 {
  padding-bottom: 80px;
}

.pd_top_80 {
  padding-top: 80px !important;
}

.pb-0 {
  padding-bottom: 0;
}

/* Social Color */
.facebook a {
  color: #345895;
}

.twitter a {
  color: #00a0ee;
}

.linkedin a {
  color: #2867b2;
}

.instagram a {
  color: #4c57ea;
}

.dribbble a {
  color: #a99df5;
}

.skype a {
  color: #00aff0;
}

.rss a {
  color: #8265ff;
}

.pinterest a {
  color: #ff081e;
}

/* Default Input type */
select,
input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"] {
  display: block;
  width: 100%;
  height: 50px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black-color);
  background-color: var(--white-color);
  background-clip: padding-box;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}

textarea.form-control {
  display: block;
  width: 100%;
  height: 140px;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  color: var(--black-color);
  background-color: var(--white-color);
  background-clip: padding-box;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}

textarea.form-control ::-webkit-input-placeholder {
  color: var(--body-color);
  font-weight: 500;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea.form-control:focus {
  border-color: var(--second-color);
  outline: none;
  outline-offset: 0px;
  box-shadow: none;
}

.form-select:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.form-select,
option {
  color: var(--body-color);
}

.form-select {
  border: none;
}

.button {
  position: relative;
  min-width: 200px;
  display: inline-block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  outline: none;
  padding: 16px 30px;
  background: var(--second-color);
  color: var(--white-color);
  border: 2px solid transparent;
  border-radius: 0;
  overflow: hidden;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.5s ease;
}

.button:hover {
  background: var(--primary-color);
  color: var(--white-color);
  transition: all 0.5s ease;
}

.button.alt {
  background: var(--primary-color);
  color: var(--white-color);
}

.button.alt:hover {
  background: var(--second-color);
  color: var(--white-color);
}

/*
 * Shortcode: Pagination
 * -----------------------------------------------
*/
.pagination-div {
  display: inline-block;
  width: 100%;
  margin-top: 30px;
}

.pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  justify-content: center;
}

.pagination li {
  margin-right: 10px;
}

.pagination li:last-child {
  margin-right: 0px;
}

.pagination li a {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  color: var(--black-color);
  border: 1px solid #6e7a7a;
  border-radius: 4px;
}

.pagination li a.page-number {
  background: rgba(255, 169, 3, 0.3);
  color: var(--second-color);
  border: 1px solid transparent;
}

.pagination li a:hover,
.pagination li a.current {
  background: var(--second-color);
  color: var(--white-color);
}

.experience .about .section_header .section_title {
  font-size: 44px;
  line-height: 54px;
}

.experience
  .about
  .section_header
  .about_below
  .about_below_content
  .about_below_content_text {
  width: unset;
  margin-left: 20px;
}

.experience .section_header {
  margin-top: 0;
}

.experience .section_header .section_title {
  color: var(--text-heading-color);
  font-size: 52px;
  line-height: 60px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
}

.experience .section_header .section_sub_title {
  color: var(--second-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.experience .section_header .shadow_icon {
  margin-bottom: 20px;
}

.group_image_holder {
  position: relative;
  z-index: 9;
}

.group_image_holder .image_object {
  position: relative;
  overflow: hidden;
}

.group_image_holder .object img {
  position: absolute;
}

.group_image_holder .object img:first-child {
  top: 92px;
  right: 39px;
  animation: gruopimg-move1 4s infinite;
}

.group_image_holder .object img:nth-child(2) {
  bottom: 70px;
  left: 41%;
  transform: translateX(-10%);
  animation: gruopimg-move1 4s infinite;
}

.group_image_holder .object img:nth-child(3) {
  bottom: 71px;
  right: 39px;
  transform: translateX(10px);
  animation: gruopimg-move1 4s infinite;
}

.group_image_holder .object img:nth-child(4) {
  top: 0;
  left: 0;
}

@keyframes gruopimg-move1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.expe_box {
  position: absolute;
  left: -30px;
  bottom: 90px;
  background: var(--second-color);
  color: var(--white-color);
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  width: 150px;
  height: 140px;
  text-align: center;
  display: table;
}

.expe_box h1 {
  font-size: 48px;
  font-weight: 800;
}

.expe_box .expe_box_inner {
  display: table-cell;
  vertical-align: middle;
  z-index: 1;
  position: relative;
  background: #ffa903;
  width: 150px;
  height: 140px;
  line-height: 22px;
}

.expe_box::after {
  content: "";
  position: absolute;
  left: 20px;
  width: 100px;
  height: 100px;
  background: #b47700;
  top: -44px;
  transform: rotate(50deg);
  z-index: -1;
}

.experience_content_margin .section_header {
  margin-bottom: 50px;
}

.experience_content {
  padding-top: 25px;
}

.experience_content .section_header {
  margin-bottom: 30px;
}

.experience_content .section_header .section_desc {
  color: #6e7a7a;
  margin: 0;
}

.experience_content .section_header .short_detail {
  background: #f2f7f7;
  font-size: 14px;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  line-height: 24px;
  letter-spacing: -0.2px;
  padding: 10px 30px 10px 20px;
  border-left: 5px solid var(--second-color);
  margin-bottom: 15px;
}

.experience_content .section_header .section_call {
  margin-top: 40px;
  padding-top: 40px;
  display: flex;
  border-top: 1px solid #cacfeb;
  align-items: center;
  width: 85%;
}

.experience_content .section_header .section_call .call_info {
  margin-left: 20px;
}

.experience_content .section_header .section_call .call_info p {
  font-size: 17px;
  line-height: 28px;
  font-weight: 600;
}

.experience_content .section_header .section_call .call_info p span {
  color: var(--second-color);
}

.experience_content .section_header .section_call .call_info h4 {
  color: var(--heading-color);
  font-weight: 800;
  line-height: 32px;
  font-size: 24px;
  margin-top: 10px;
}

.experience_content .section_header .section_call .call_info h4 span {
  color: var(--second-color);
}

.experience_content .about_below {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.experience_content .about_below .about_below_content {
  display: flex;
  width: 100%;
}

.experience_content
  .about_below
  .about_below_content
  .about_below_content_text {
  margin-left: 20px;
  width: 72%;
}

.experience_content
  .about_below
  .about_below_content
  .about_below_content_text
  h5 {
  color: #181d35;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
}

.experience_content
  .about_below
  .about_below_content
  .about_below_content_text
  p {
  color: #272d2d;
  margin-top: 3px;
  font-size: 17px;
  line-height: 28px;
}

.experience_content .about_below .about_below_content i {
  background: #f1f3ff;
  color: #00234b;
  width: 56px;
  height: 56px;
  text-align: center;
  line-height: 55px;
  font-size: 32px;
}

.experience_content .about_below .about_below_content i::before {
  font-weight: 700;
}

.experience_content .video_button a {
  position: relative;
  width: 60px;
  height: 60px;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  font-size: 20px;
  color: #fff;
  background: var(--second-color);
}

.experience_content .video_button a i {
  position: relative;
  margin-left: 5px;
}

.experience_content .video_button a:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 60px;
  height: 60px;
  z-index: -1;
  background: var(--second-color);
  border-radius: 100%;
  animation: pulse-border 1500ms ease-out infinite;
}

.experience_content .video_button span {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--dark-color);
  margin-left: 15px;
}

.experience_content .appoint {
  position: relative;
  color: var(--dark-color);
  font-weight: 700;
  margin-top: 30px;
  display: inline-block;
}

.experience_content .appoint span {
  color: var(--second-color);
}

.experience_content .appoint:after {
  content: "";
  position: absolute;
  background: #ffe9e9;
  width: 100%;
  height: 12px;
  left: 0;
  bottom: -3px;
  z-index: -1;
}

.experience_content .button {
  border-radius: 0;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 30px;
}

.experience_content.about .about_below {
  display: block;
}

.experience_content.about .about_below .about_below_content {
  margin-bottom: 30px;
}

.experience_content.about .about_below .about_below_content:last-child {
  margin-bottom: 0;
}

.experience_content.about .about_below .about_below_content img {
  width: 80px;
  height: 80px;
  padding: 17px;
  margin-right: auto;
  background: var(--white-color);
  box-shadow: 0px 0px 9px 5px rgba(229, 229, 229, 0.5);
  border-radius: 5px;
}

.bglayer_1 {
  background-image: url("../../../public/images/about/abt.png");
  padding-bottom: 87px;
  background-position: right;
  background-repeat: no-repeat;
}

.another_bg_image {
  position: relative;
  padding: 39px 0;
}

.another_bg_image::before {
  position: absolute;
  content: "";
  background: var(--heading-color);
  width: 789px;
  height: 100%;
  top: 0;
  opacity: 0.9;
}

.another_bg_image::after {
  position: absolute;
  content: "";
  background: url("../../../public/images/about/abt.png");
  width: 789px;
  top: 0;
  height: 100%;
  background-repeat: no-repeat;
}

.upper_sm_img {
  position: relative;
}

.upper_sm_img::before {
  position: absolute;
  content: url("../../../public/images/about/s1.png");
  top: -39px;
  z-index: 9;
}

.upper_sm_img .group_image_holder {
  padding: 10px 0px 0px 10px;
}

.upper_sm_img .group_image_holder img {
  position: relative;
  z-index: 99;
}

.upper_sm_img .group_image_holder::after {
  position: absolute;
  content: "";
  background: var(--second-color);
  width: 88%;
  height: 100%;
  top: -12px;
  left: -11px;
}

.top_feature {
  text-align: center;
  padding: 90px 0;
}

.top_feature .logo_image_holder {
  margin-top: 90px;
}

.top_feature .logo_image_holder img {
  margin: 0px 15px;
}

.top_feature .content_inner {
  padding: 0px 100px;
}

.top_feature .content_inner h1 {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  color: var(--dark-color);
  margin-top: 30px;
}

.service {
  position: relative;
}

.service .section_header {
  margin-bottom: 25px;
}

.services_content_flex_cenrer {
  margin-left: 20px;
}

.section2.service {
  padding: 110px 0 80px 0;
}

.services_inner_page .service_inner_block {
  margin-top: 0 !important;
  margin-left: 0;
}

.service_inner_block {
  padding: 50px 24px;
  transition: all 0.5s ease-in-out;
  box-shadow: 4px 4px 15px 10px rgba(51, 60, 68, 0.05);
  border-radius: 10px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  margin-top: 25px;
  margin-left: 15px;
}

.service_inner_block > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.service_inner_block::after {
  position: absolute;
  content: "";
  background: rgba(0, 42, 92, 0);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.service_inner_block .service_content {
  position: relative;
  z-index: 999;
}

.service_inner_block .service_content h4 {
  color: var(--heading-color);
  font-weight: 700;
  margin: 30px 0 15px 0;
  line-height: 26px;
  transition: all 0.5s ease-in-out;
}

.service_inner_block .service_content p {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 15px;
  transition: all 0.5s ease-in-out;
}

.service_inner_block .service_content a {
  color: var(--heading-color);
  font-size: 14px;
  font-weight: 600;
  transition: all 0.5s ease-in-out;
}

.service_inner_block .service_content a i {
  color: var(--second-color);
  margin-left: 5px;
}

.service_inner_block:hover > img {
  /*opacity: 1;*/
}

.service_inner_block:hover::after {
  /*background: rgba(0, 42, 92, 0.7);*/
}

.service_inner_block:hover .service_content h4 {
  /*color: var(--white-color);*/
}

.service_inner_block:hover .service_content p {
  /*color: #CACFEB;*/
}

.service_inner_block:hover .service_content a {
  /*color: var(--white-color);*/
}

.service_inner_block:hover .icon_img img {
  /*transform: rotateY(180deg);*/
}

.service_inner_block .icon_img img {
  width: unset;
  display: unset;
  z-index: 99;
  position: relative;
  transition: all 0.8s ease-in-out;
}

.service_inner.bg_2 .service_content h6 {
  color: var(--white-color);
}

.service_inner.bg_2 .service_content a i {
  color: var(--white-color);
  font-size: 16px;
}

.service_inner {
  display: flex;
  padding-left: 55px;
  padding-top: 25px;
  transition: all 0.5s ease-in;
  height: 100%;
  overflow: hidden;
}

.service_inner .icon_img {
  position: relative;
  flex-shrink: 0;
}

.service_inner .icon_img::before {
  position: absolute;
  content: "";
  background: #cacfeb;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  /*opacity: .8;*/
  left: 12px;
  top: 10px;
}

.service_inner .icon_img img {
  width: 59px;
  min-height: 59px;
  height: 59px;
  z-index: 9;
  position: relative;
}

.service_inner .service_content h6 {
  color: var(--second-color);
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.service_inner .service_content h4 {
  color: var(--white-color);
  margin-bottom: 30px;
  margin-top: 10px;
}

.service_inner .service_content h4 a {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.service_inner .service_content a {
  color: var(--white-color);
  font-size: 18px;
  font-weight: 600;
}

.service_inner .service_content a i {
  margin-left: 10px;
  color: var(--second-color);
}

.service_inner .main_img {
  margin-left: auto;
  align-self: flex-end;
  text-align: right;
}

.owl_service.owl-carousel .owl-item.active.center .service_inner_block > img {
  opacity: 1;
}

.owl_service.owl-carousel .owl-item.active.center .service_inner_block::after {
  background: rgba(0, 42, 92, 0.7);
}

.owl_service.owl-carousel
  .owl-item.active.center
  .service_inner_block
  .service_content
  h4 {
  color: var(--white-color);
}

.owl_service.owl-carousel
  .owl-item.active.center
  .service_inner_block
  .service_content
  p {
  color: #cacfeb;
}

.owl_service.owl-carousel
  .owl-item.active.center
  .service_inner_block
  .service_content
  a {
  color: var(--white-color);
}

.owl_service.owl-carousel
  .owl-item.active.center
  .service_inner_block
  .icon_img
  img {
  transform: rotateY(180deg);
}

.owl_service.owl-carousel.owl-theme .owl-nav {
  position: absolute;
  top: -132px;
  right: 30%;
  width: auto;
}

.owl_service.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--primary-color);
  color: var(--second-color);
}

.owl_service.owl-carousel.owl-theme .owl-nav .owl-prev {
  left: auto;
  right: 70px;
}

.owl_service.owl-carousel.owl-theme .owl-nav .owl-next {
  right: 0px;
}

/*----- Innerpage Sidenav ------*/
.widget.widget_menu {
  padding: 0px;
  background: transparent;
}

.sidenav ul {
  padding: 0px;
  margin: 0px;
}

.sidenav ul li {
  list-style: none;
  margin-top: -1px;
}

.sidenav ul li a {
  position: relative;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 28px;
  color: #00234b;
  list-style: none;
  background: #fff;
  padding: 16px 20px 16px 65px;
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
}

.sidenav ul li a i {
  font-size: 36px;
  margin-right: 15px;
  color: var(--second-color);
}

.sidenav ul li a:hover {
  background: var(--second-color);
  color: var(--white-color);
}

.sidenav ul li a:hover i {
  color: var(--white-color);
}

.sidenav ul li a:hover img:first-child {
  opacity: 0;
  visibility: hidden;
}

.sidenav ul li a:hover img:last-child {
  opacity: 1;
  visibility: visible;
}

.sidenav ul li a img:first-child {
  opacity: 1;
  visibility: visible;
  margin-left: -34px;
  margin-right: 14px;
  transition: all 0.5s ease-in;
}

.sidenav ul li a img:last-child {
  opacity: 0;
  visibility: hidden;
  margin-left: -34px;
  margin-right: 14px;
  transition: all 0.5s ease-in;
}

.sidenav ul li.active a {
  background: var(--second-color);
  color: var(--white-color);
}

.sidenav ul li.active a i {
  color: var(--white-color);
}

.sidenav ul li.active a img:first-child {
  opacity: 0;
}

.sidenav ul li.active a img:last-child {
  opacity: 1;
  visibility: visible;
}

.widget.widget_side_contact {
  padding: 0px;
  background: transparent;
}

.side_contact {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}

.side_contact .side_contact_top {
  position: relative;
  background: var(--heading-color);
  padding: 25px 30px;
  border-radius: 3px 3px 0px 0px;
}

.side_contact .side_contact_top .side_contact_inner {
  position: relative;
  color: var(--white-color);
  padding: 15px 20px;
  border: 1px dashed var(--second-color);
  background: #0a3f34;
}

.side_contact .side_contact_top .side_contact_inner p {
  position: relative;
  z-index: 1;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--second-color);
}

.side_contact .side_contact_top .side_contact_inner span {
  font-size: 20px;
  position: relative;
  z-index: 1;
  font-weight: 500;
}

.side_contact .side_contact_top img {
  position: absolute;
  bottom: 0;
  right: 8px;
}

.side_contact .side_phone_inner {
  position: relative;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  color: var(--white-color);
  line-height: 32px;
  padding: 15px 30px;
  word-break: break-word;
  background: var(--second-color);
}

.side_contact .side_phone_inner > * {
  position: relative;
  text-align: left;
}

.side_contact .side_phone_inner img {
  max-width: 50px;
  margin-right: 15px;
}

.iconbox {
  position: relative;
  margin: 0px 0px 30px 0px;
  box-shadow: 0px 0px 30px rgba(255, 59, 62, 0.09);
}

.iconbox .iconbox_wrapper {
  border-radius: 0px 0px 0px 0px;
  display: block;
  text-align: left;
}

.iconbox .iconbox_wrapper .iconbox_image {
  position: relative;
}

.iconbox .iconbox_wrapper .iconbox_image img {
  width: 100%;
}

.iconbox .iconbox_wrapper .iconbox_icon {
  position: absolute;
  right: 50px;
  bottom: -33px;
  z-index: 1;
  border-radius: 100%;
}

.iconbox .iconbox_wrapper .iconbox_icon img {
  width: auto;
  transition: all 0.8s ease-in-out;
}

.iconbox .iconbox_wrapper .iconbox_icon a {
  position: relative;
  width: 66px;
  height: 66px;
  line-height: 66px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  border-radius: 100%;
  margin: 0 auto;
  background: var(--second-color);
}

.iconbox .iconbox_wrapper .iconbox_icon a:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  z-index: -1;
  border: 7px solid rgba(255, 169, 3, 0.4);
  border-radius: 100%;
}

.iconbox .iconbox_wrapper .iconbox_content {
  position: relative;
  background: #fff;
  padding: 45px 35px 45px 45px;
  margin: 0px 0px 0px 0px;
}

.iconbox .iconbox_wrapper .iconbox_content h3 {
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  color: #181d35;
  margin-bottom: 20px;
}

.iconbox .iconbox_wrapper .iconbox_content h3 a {
  color: #181d35;
}

.iconbox .iconbox_wrapper .iconbox_content h3 a:hover {
  color: var(--second-color);
}

.iconbox .iconbox_wrapper .iconbox_content p {
  font-size: 17px;
  color: #6e7a7a;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 0px;
}

.iconbox .iconbox_wrapper .iconbox_content .read_more {
  margin-top: 30px;
}

.iconbox .iconbox_wrapper .iconbox_content .read_more a {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--heading-color);
  padding-left: 25px;
  display: inline-block;
  overflow: hidden;
  letter-spacing: 1px;
}

.iconbox .iconbox_wrapper .iconbox_content .read_more a span {
  position: relative;
  z-index: 2;
  background: var(--white-color);
}

.iconbox .iconbox_wrapper .iconbox_content .read_more a::before {
  content: "\f30f";
  font-family: "Ionicons";
  position: absolute;
  left: 0;
  font-size: 22px;
  font-weight: 400;
  color: var(--second-color);
  transition: all 0.7s ease;
}

.iconbox .iconbox_wrapper .iconbox_content .read_more a:hover {
  padding-left: 0px;
  padding-right: 25px;
  color: var(--second-color);
}

.iconbox .iconbox_wrapper .iconbox_content .read_more a:hover::before {
  left: calc(100% - 15px);
}

.iconbox .iconbox_wrapper:hover .iconbox_icon img {
  transform: rotateY(180deg);
}

.service_flex {
  display: flex;
}

.service_flex .service_inner_block {
  margin-right: 30px;
}

.service_flex .service_inner_block:last-child {
  margin-right: 0;
}

.side_owl {
  position: absolute;
  left: -13px;
}

.header {
  position: fixed;
  top: 0;
}

.header .top_bar {
  max-height: 200px;
  overflow: visible;
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-in-out;
}

.header .top_bar.top-up {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
}

.header {
  width: 100%;
  background: var(--white-color);
  z-index: 999;
  transition: all 0.5s ease-in-out;
}

.header .top_bar {
  position: relative;
  border-bottom: 1px solid #ececec;
  z-index: 2;
}

.header .top_bar .top_bar_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
}

.header .header_info {
  display: flex;
  align-items: center;
  margin-left: 40px;
  flex-flow: row wrap;
}

.header .header_info .free_contact {
  margin-left: 20px;
}

.header .header_info .free_contact a {
  background: var(--heading-color);
  border-radius: 0;
  color: var(--second-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 30px;
  line-height: 26px;
  transition: all 0.5s ease-in;
}

.header .header_info .free_contact a:hover {
  background: var(--second-color);
  color: var(--primary-color);
}

.header .schedule {
  position: relative;
  font-size: 14px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  padding: 0px 20px;
}

.header .schedule .hide_icon {
  position: relative;
}

.header .schedule .hide_icon::after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + 10px);
  background-color: var(--white-color);
  color: currentColor;
  z-index: 1;
  padding: 0px 10px;
  opacity: 0;
  visibility: hidden;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

.header .schedule .hide_icon:hover::after {
  opacity: 1;
  visibility: visible;
}

.header .schedule .hide_icon span {
  background: var(--heading-color);
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--second-color);
}

.header .schedule div {
  color: var(--body-color);
  font-size: 14px;
  margin-left: 14px;
  font-family: "Nunito Sans", sans-serif;
  line-height: 26px;
  letter-spacing: -0.2px;
}

.header .schedule div strong {
  color: var(--heading-color);
}

.header .schedule i {
  color: var(--second-color);
  font-size: 16px;
}

.header .phone {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: auto;
  line-height: 18px;
  padding: 0 15px;
  margin-right: 10px;
}

.header .phone > * {
  position: relative;
  margin-left: 15px;
}

.header .phone img {
  margin-right: 10px;
}

.header .phone span {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: -0.2px;
}

.header .phone .phn_number {
  color: #0a3f34;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
}

.header .phone i {
  color: var(--white-color);
  font-size: 18px;
  width: 38px;
  height: 38px;
  background: var(--second-color);
  border-radius: 100%;
}

.header .phone i::before {
  content: "\f095";
  left: 12px;
  position: absolute;
  top: 10px;
}

.header .phone.phn_color i {
  background: #cacfeb;
  color: var(--heading-color);
}

.header .header_social {
  display: flex;
  align-items: center;
}

.header .header_social h6 {
  color: var(--second-color);
  font-size: 14px;
  font-weight: 700;
  margin-right: 15px;
  line-height: 26px;
}

.header .header_social .top_social {
  padding: 0px;
  margin-bottom: 0px;
}

.header .header_social .top_social li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  padding-right: 10px;
}

.header .header_social .top_social li:first-child {
  padding-left: 0px;
}

.header .header_social .top_social li:last-child {
  margin-right: 0px;
}

.header .header_social .top_social li a {
  font-size: 15px;
  text-align: center;
  color: var(--heading-color);
  display: block;
  outline: none;
}

.header .ma5menu__toggle {
  background: transparent;
  width: 40px;
  height: 40px;
  line-height: 38px;
  padding: 0px;
  color: var(--second-color);
  font-size: 24px;
  border: 1px solid var(--second-color);
  display: none;
  transition: all 0.5s ease;
  flex-shrink: 0;
}

.header .ma5menu__toggle:focus {
  outline: none;
}

.header .ma5menu__toggle:hover {
  background: var(--second-color);
  color: var(--white-color);
}

.header .header_search {
  padding-left: 15px;
  border-left: 1px solid #ececec;
  transition: all 0.5s ease;
}

.header .header_search .form-control {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  padding: 0px 0px 0px 50px;
  height: 50px;
  border: 0px solid var(--primary-color);
  border-radius: 30px;
  color: var(--second-color);
  background: #ffd2d2;
  transition: all 0.5s ease;
  width: 100%;
}

.header .header_search .form-control:focus {
  box-shadow: none;
}

.header .header_search.active {
  width: 100%;
}

.header .header_search .form-control-submit {
  border: 0px;
  background: transparent;
  font-size: 22px;
  color: var(--heading-color);
  cursor: pointer;
}

.header .header_search .form-control-submit:focus {
  outline: none;
}

.header .header_search ::-webkit-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--second-color);
}

.header .header_search ::-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--second-color);
}

.header .header_search :-ms-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--second-color);
}

.header .header_search :-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--second-color);
}

.header .header_search :placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  color: var(--second-color);
}

.header .logo {
  position: relative;
  flex-shrink: 0;
}

.header .logo a {
  display: inline-block;
}

.header .logo a img {
  width: 100%;
  min-width: 110px;
  max-width: 120px;
  transition: all 0.5s ease;
}

.header .logo a.dark_mode_logo {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  visibility: hidden;
}

.header .middle_bar_inner {
  display: flex;
  align-items: center;
}

.header .header_right_part {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header .header_right_part #open_search {
  position: absolute;
  top: 100%;
  right: 0;
  margin: 0px 2px 0px 0px;
  width: 260px;
  height: 0;
  overflow: hidden;
  transition: all 0.5s ease;
  z-index: 3;
}

.header .header_right_part #open_search.active {
  height: 50px;
}

.header .header_right_part #open_search .search_form {
  width: 100%;
  max-width: 100%;
}

.header .header_right_part #open_search .search_form .form-control {
  height: 50px;
  border-radius: 0px;
  padding-left: 35px;
  border: 1px solid var(--second-color);
}

.header .header_right_part #open_search .search_form .form-control-submit {
  position: absolute;
  top: 0;
  left: 0;
  border: 0px;
  background: transparent;
  padding: 10px;
  font-size: 22px;
  color: var(--second-color);
  cursor: pointer;
}

.header .mainnav {
  margin-left: 100px;
}

.header .mainnav ul.main_menu {
  padding: 0px;
  margin: 0px;
}

.header .mainnav ul.main_menu li {
  border: 0;
  list-style: none;
  line-height: 1;
  display: inline-block;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.header .mainnav ul.main_menu li a {
  border: 0;
  list-style: none;
  display: inline-block;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 26px;
  text-decoration: none;
  text-align: center;
  color: var(--body-color);
  padding: 25px 20px 25px 0px;
}

.header .mainnav ul.main_menu li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: translate3d(0px, 0px, 0px);
}

.header .mainnav ul.main_menu li.current > a,
.header .mainnav ul.main_menu li.active > a,
.header .mainnav ul.main_menu li:hover > a {
  color: var(--second-color);
}

.header .mainnav ul.main_menu li ul {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  min-width: 250px;
  margin: 0px 0px;
  padding: 0px 0px;
  z-index: 5;
  transition: all 0.5s ease;
  transform: translate3d(15px, 0px, 0px);
  background: var(--primary-color);
  border-top: 3px solid var(--second-color);
  border-radius: 0px 0px 10px 10px;
}

.header .mainnav ul.main_menu li ul li {
  width: 100%;
  padding: 0px 25px;
}

.header .mainnav ul.main_menu li ul li a {
  font-size: 16px;
  font-weight: 600;
  color: var(--white-color);
  padding: 13px 20px;
  width: 100%;
  text-decoration: none;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.header .mainnav ul.main_menu li ul li a:hover {
  color: var(--second-color);
}

.header .mainnav ul.main_menu li ul li:last-child > a {
  border-bottom: none;
}

.header .mainnav ul.main_menu li ul li.menu-item-has-children > a:before {
  content: "\f105";
  font-family: "FontAwesome";
  speak: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.header .mainnav ul.main_menu li ul ul {
  left: 100%;
  top: 0;
  border-top: 0px solid var(--second-color);
  border-radius: 10px 10px 10px 10px;
}

.header .mainnav ul.main_menu li ul ul ul ul {
  left: auto;
  right: 100%;
}

.header .mainnav ul.main_menu > li.menu-item-has-children > a::before {
  content: "\f107";
  color: var(--body-color);
  font-family: "FontAwesome";
  speak: none;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  transition: all 0.5s ease-in-out;
}

.header .mainnav ul.main_menu > li.menu-item-has-children:hover a::before {
  color: var(--second-color);
}

.header .mainnav ul.main_menu > li {
  margin: 0px 20px;
}

.header .mainnav ul.main_menu > li.active > a {
  color: var(--primary-color);
}

.header .mainnav ul.main_menu > li.active > a::after {
  position: absolute;
  content: "";
  width: 62px;
  height: 2px;
  background: var(--second-color);
  height: 4px;
  background: rgba(255, 169, 3, 0.5);
  left: 0;
  top: 39px;
}

.header .mainnav ul.main_menu > li.active > a::before {
  color: var(--primary-color);
}

.header .mainnav ul.main_menu > li:first-child {
  margin-left: 0px;
}

.header .mainnav ul.main_menu > li:last-child {
  margin-right: 0px;
}

.header.type_2 {
  background: var(--white-color);
}

.header.type_2 .top_bar .top_bar_inner {
  justify-content: space-between;
}

.header.type_2 .phone {
  padding: 17px 30px;
}

.header.type_2 .logo {
  position: relative;
}

.header.type_2 .aside_open {
  display: none;
}

.header_color {
  background: #eff3fb;
}

/*------- Testimonial -------*/
.testimonial {
  padding-top: 75px;
  background: #f2f7f7;
}

@media (max-width: 394px) {
  .testimonial {
    padding-top: 40px;
  }
  .testimonial.pd_btom_80 {
    padding-bottom: 0px;
  }
}

.testimonial .owl_testimonial1.owl-theme .owl-nav [class*="owl-"] {
  background: var(--white-color);
}

.testimonial .owl_testimonial1.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--primary-color);
  color: var(--second-color);
}

.testibox1 {
  position: relative;
  padding-bottom: 30px;
}

.testibox1:hover::before {
  opacity: 0;
}

.testibox1:hover::after {
  opacity: 1;
}

.testibox1 .testibox_inner {
  position: relative;
  background: #fff;
  padding: 30px 25px 40px 45px;
  border: 1px solid #eeeeee;
  transition: all 0.8s ease-in;
}

.testibox1 .testibox_inner:hover {
  background: var(--heading-color);
}

.testibox1 .testibox_inner:hover ul li.text {
  color: var(--white-color);
}

.testibox1 .testibox_inner:hover p {
  color: var(--white-color);
}

.testibox1 .testibox_inner:hover h3 {
  color: var(--white-color);
}

.testibox1 .testibox_inner:before {
  content: url("../../../public/images/border_radius_shape.png");
  position: absolute;
  bottom: -26px;
  right: -8px;
  z-index: 2;
}

.testibox1:before {
  content: url("../../../public/images/quote.png");
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 2;
  transition: all 0.8s ease-in;
}

.testibox1:after {
  content: url("../../../public/images/quote2.png");
  position: absolute;
  top: 30px;
  right: 50px;
  z-index: 2;
  opacity: 0;
  transition: all 0.8s ease-in;
}

.testibox1 .testi-top {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.testibox1 .testi-img {
  width: 60px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.testibox1 h3 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  color: var(--heading-color);
  font-size: 15px;
  line-height: 10px;
  transition: all 0.9s ease-in;
}

.testibox1 span {
  font-family: "Mulish", sans-serif;
  font-size: 11px;
  line-height: 26px;
  font-weight: 700;
  color: var(--testimonial-below-color);
  text-transform: uppercase;
  transition: all 0.9s ease-in;
}

.testibox1 p {
  color: var(--testimonial-paragraph-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  transition: all 0.9s ease-in;
}

.testibox1 ul {
  padding: 0px;
  margin-bottom: 20px;
}

.testibox1 ul li {
  display: inline-block;
  font-size: 16px;
  color: var(--second-color);
}

.testibox1 ul li.text {
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: var(--heading-color);
  margin-right: 10px;
  line-height: 24px;
  transition: all 0.9s ease-in;
}

.owl_testimonial1 .owl-item.active.center .item .testibox1 .testibox_inner {
  background: var(--heading-color);
}

.owl_testimonial1
  .owl-item.active.center
  .item
  .testibox1
  .testibox_inner
  ul
  li.text {
  color: var(--white-color);
}

.owl_testimonial1 .owl-item.active.center .item .testibox1 .testibox_inner p {
  color: var(--white-color);
}

.owl_testimonial1 .owl-item.active.center .item .testibox1 .testibox_inner h3 {
  color: var(--white-color);
}

.owl_testimonial1 .owl-item.active.center .item .testibox1::after {
  opacity: 1;
}

.testibox2 {
  position: relative;
  padding-bottom: 30px;
}

.testibox2 .testibox_inner {
  position: relative;
  transition: all 0.8s ease-in;
}

.testibox2 .testi-top {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.testibox2 .testi-img {
  width: 60px;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.testibox2 h4 {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  color: var(--heading-color);
  font-size: 15px;
  line-height: 10px;
  text-transform: uppercase;
  transition: all 0.9s ease-in;
}

.testibox2 h6 {
  font-family: "Mulish", sans-serif;
  font-size: 17px;
  line-height: 28px;
  font-weight: 500;
  color: var(--testimonial-below-color);
  transition: all 0.9s ease-in;
}

.testibox2 p {
  color: var(--testimonial-paragraph-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  transition: all 0.9s ease-in;
}

.testibox2 ul {
  padding: 0px;
  margin-bottom: 15px;
}

.testibox2 ul li {
  display: inline-block;
  font-size: 16px;
  color: var(--second-color);
}

.testibox2 ul li.text {
  font-family: "Mulish", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: var(--heading-color);
  margin-right: 10px;
  line-height: 24px;
  transition: all 0.9s ease-in;
}

.testimonial_image {
  text-align: right;
  position: relative;
}

.testimonial_image .first_img {
  position: absolute;
  top: -100px;
  left: 45px;
  animation: object-move1 10s infinite;
}

@keyframes object-move1 {
  0% {
    top: -100px;
  }
  50% {
    top: -60px;
  }
  100% {
    top: -100px;
  }
}

.testimonial_image .last_img {
  position: absolute;
  bottom: -105px;
  right: -100px;
  animation: object-move2 10s infinite;
}

@keyframes object-move2 {
  0% {
    bottom: -105px;
  }
  50% {
    bottom: -70px;
  }
  100% {
    bottom: -105px;
  }
}

.testimonial_image .middle_img {
  position: relative;
  z-index: 99;
}

.testimonial_image .image_color {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  padding-right: 6px;
}

.testimonial_image .image_color::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  background: var(--second-color);
  width: 170px;
  height: 283px;
}

.testimonial2 {
  padding-top: 60px;
  padding-bottom: 60px;
  background-image: url("../../../public/images/others/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial2 .slider {
  padding: 100px 0 70px 0px;
}

.testimonial2 .owl-carousel .owl-item img {
  width: auto;
}

.testimonial2 .owl_testimonial2.owl-theme .owl-nav [class*="owl-"] {
  background: var(--white-color);
}

.testimonial2 .owl_testimonial2.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--primary-color);
  color: var(--second-color);
}

.testimonial2 .owl_testimonial2.owl-theme .owl-nav .owl-prev {
  left: 0px;
}

.testimonial2 .owl_testimonial2.owl-theme .owl-nav .owl-next {
  right: 0px;
}

.testimonial2 .owl-dots {
  position: absolute;
  bottom: unset;
  top: 50%;
  right: 20px;
  width: unset;
}

.testimonial2 .owl-dots .owl-dot {
  display: block;
}

.upper_img {
  margin-bottom: 30px;
}

.testibox_others p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.testi-info_others h4 {
  color: var(--heading-color);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
}

/*.project_details_inner{
    .post_content{
        .fulltext{
            .testibox1{
                &:hover{
                    .testibox_inner{
                        background: transparent;
                    }
                }
            }
        }
    }
}*/
.owl-theme .owl-dots .owl-dot span {
  width: 12px;
  height: 12px;
  background: var(--heading-color);
}

.owl-theme .owl-dots .owl-dot.active span {
  background: var(--second-color);
}

.blog.section {
  padding-bottom: 80px !important;
}

@media (max-width: 394px) {
  .blog.section {
    padding-bottom: 0 !important;
  }
}

.blog .section_header {
  margin-right: 100px;
}

.blog .section_header h1 {
  color: var(--heading-color);
}

.blog .section_header_width_no_margin {
  width: 680px;
  margin: 0 auto 60px auto;
}

.blog .section_header_width_no_margin .section_sub_title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 30px;
  color: var(--second-color);
}

.blog .section_header_width_no_margin .section_title {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  color: var(--heading-color);
  letter-spacing: 0px;
  margin-top: 6px;
  margin-bottom: 20px;
}

.blog .read_all a {
  background: var(--heading-color);
  color: var(--white-color);
}

.blog .read_all a:hover {
  background: var(--second-color);
}

/*
 * Shortcode: blog-grid
 * -----------------------------------------------
*/
.blog_post {
  position: relative;
  margin: 0px 0px 30px 0px;
}

.blog_post .post_img {
  position: relative;
}

.blog_post .post_img img {
  width: 100%;
}

.blog_post .calendar {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: var(--second-color);
  padding: 12px 0px;
  width: 60px;
  height: 60px;
  text-align: center;
  display: table;
}

.blog_post .calendar a {
  display: table-cell;
  vertical-align: middle;
  color: var(--white-color);
  font-size: 14px;
  line-height: 16px;
}

.blog_post .calendar a .date {
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 20px;
}

.blog_post .post_content {
  position: relative;
  background: #fff;
  padding: 35px 25px 35px 45px;
  margin: 0px 0px 0px 0px;
  border: 1px solid #eeeeee;
  border-top: 0;
}

.blog_post .post_content .categ {
  margin-bottom: 15px;
}

.blog_post .post_content .categ a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white-color);
  background: var(--second-color);
  padding: 2px 15px;
  border-radius: 5px;
  display: inline-block;
}

.blog_post .post_content .post_title {
  font-weight: 800;
  color: #181d35;
  margin-bottom: 20px;
  line-height: 32px;
}

.blog_post .post_content .post_title a {
  color: #181d35;
}

.blog_post .post_content .post_title a:hover {
  color: var(--second-color);
}

.blog_post .post_content .post_intro {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 0px;
}

.blog_post .post_content .post_footer {
  margin-top: 30px;
}

.blog_post .post_content .read_more a {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--heading-color);
  padding-left: 25px;
  display: inline-block;
  overflow: hidden;
  letter-spacing: 0.7px;
}

.blog_post .post_content .read_more a::before {
  content: "\f30f";
  font-family: "Ionicons";
  position: absolute;
  left: 0;
  font-size: 22px;
  font-weight: 400;
  color: var(--second-color);
  transition: all 0.7s ease;
}

.blog_post .post_content .read_more a:hover {
  padding-left: 0px;
  padding-right: 25px;
  color: var(--second-color);
}

.blog_post .post_content .read_more a:hover::before {
  left: calc(100% - 15px);
}

.blog_post.blog_post2 {
  box-shadow: 0px 9px 20px 5px rgba(170, 182, 192, 0.2);
  margin-left: 10px;
  margin-right: 10px;
}

.blog_post.blog_post2 .calendar.position_two {
  top: 20px;
  left: 20px;
  background: var(--primary-color);
}

.blog_post.blog_post2 .post_content {
  padding: 25px;
}

.blog_post.blog_post2 .post_content .post_header .post_subtitle {
  display: flex;
  margin-bottom: 10px;
}

.blog_post.blog_post2 .post_content .post_header .post_subtitle img {
  width: 14px;
  height: 14px;
}

.blog_post.blog_post2 .post_content .post_header .post_subtitle h6 {
  color: #656a7c;
  font-size: 14px;
  font-weight: 700;
  margin-left: 7px;
  line-height: 15px;
  letter-spacing: -0.2px;
}

.blog_post.blog_post2 .post_content .read_more a {
  color: #656a7c;
  letter-spacing: 0;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 24px;
}

.blog_post.blog_post2 .post_content .read_more a:hover {
  color: var(--second-color);
}

.blog_post.blog_post2 .post_content .post_footer {
  margin-top: 15px;
}

.blog_list .blog_post {
  box-shadow: none;
}

.blog_list .blog_post .post_content {
  padding: 30px 0px 20px 0px;
}

.blog_list .blog_post .post_content .post_title {
  font-size: 36px;
}

.blog_list .blog_post .post_content .post_intro {
  color: #656a7c;
}

blockquote {
  position: relative;
  font-size: 27px;
  line-height: 48px;
  background: var(--second-color);
  color: var(--white-color);
  padding: 60px 90px 100px 90px;
  margin-bottom: 0px;
}

blockquote p,
blockquote .blockquote_inner {
  position: relative;
}

blockquote:before {
  content: url(../../../public/images/quote2.png);
  position: absolute;
  left: 130px;
  bottom: 50px;
}

.video_post {
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
}

.video_post .ytube_video {
  position: relative;
  overflow: hidden;
}

.video_post .ytube_video #ytvideo {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.video_post .ytube_video .ytplay_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: var(--second-color);
  text-align: center;
  font-size: 30px;
  color: var(--white-color);
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 1;
}

.video_post .ytube_video.play #ytvideo {
  opacity: 1;
}

.video_post .ytube_video.play .post_content {
  opacity: 0;
  visibility: hidden;
}

.video_post .ytube_video .post_header {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  text-align: center;
}

.video_post .ytube_video .post_header .post_title {
  margin: 0px 0px;
  color: var(--white-color);
}

.video_post .ytube_video .post_header .post_title a {
  color: var(--white-color);
}

.video_post .local_video {
  position: relative;
  overflow: hidden;
}

.video_post .local_video #video {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.video_post .local_video .play_btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  line-height: 80px;
  background: var(--second-color);
  text-align: center;
  font-size: 30px;
  color: var(--white-color);
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.5s ease;
  z-index: 1;
}

.video_post .local_video.play #video {
  opacity: 1;
}

.video_post .local_video.play .post_content {
  opacity: 0;
  visibility: hidden;
}

.video_post .local_video.play .play_btn {
  left: 50%;
  top: auto;
  bottom: 25px;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 24px;
  background: var(--second-color);
  text-align: center;
  color: var(--white-color);
  border-radius: 3px;
  cursor: pointer;
}

.video_post .local_video.play .play_btn i.ion-ios-play:before {
  content: "\f478";
}

.video_post .local_video .post_header {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
}

.video_post .local_video .post_header .post_title {
  margin: 0px 0px;
  color: var(--white-color);
}

.video_post .local_video .post_header .post_title a {
  color: var(--white-color);
}

.audio_post {
  position: relative;
  overflow: hidden;
}

iframe,
object,
embed,
iframe,
object,
iframe,
video {
  width: 100%;
  height: 100%;
  /*min-height: 500px;*/
  border: 0px solid #ffb7aa;
}

/*
 * Shortcode: Blog Details
 * -----------------------------------------------
*/
.blog_details {
  /*--- Related Posts ---*/
}

.blog_details .video_post {
  margin-top: 10px;
}

.blog_details .post_img {
  position: relative;
  margin-bottom: 60px;
}

.blog_details .post_img img {
  width: 100%;
}

.blog_details .calendar {
  position: absolute;
  top: 40px;
  left: 40px;
  background: var(--second-color);
  padding: 12px 0px;
  width: 60px;
  height: 60px;
  text-align: center;
  display: table;
}

.blog_details .calendar a {
  display: table-cell;
  vertical-align: middle;
  color: var(--white-color);
  font-size: 14px;
  line-height: 16px;
}

.blog_details .calendar a .date {
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
}

.blog_details .post_header .categ {
  margin-bottom: 15px;
}

.blog_details .post_header .categ a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white-color);
  background: var(--second-color);
  padding: 2px 15px;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: 1px;
}

.blog_details .post_header .post_title {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  text-align: left;
  margin: 18px 0px 20px 0px;
  color: #222;
}

.blog_details .post_header .tags {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-flex;
}

.blog_details .post_header .tags a {
  position: relative;
  color: var(--second-color);
  padding: 0px 10px;
}

.blog_details .post_header .tags a:first-child {
  padding-left: 0px;
}

.blog_details .post_header .tags a:last-child {
  padding-right: 0px;
}

.blog_details .post_header .tags a:last-child:after {
  display: none;
}

.blog_details .post_header .tags a:after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 7px;
  width: 2px;
  height: 2px;
  background: #fd4145;
  border-radius: 100%;
}

.blog_details blockquote {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  font-style: italic;
  background: transparent;
  color: var(--second-color);
  padding: 10px 40px 40px 40px;
  margin-bottom: 10px;
  letter-spacing: -1px;
}

.blog_details blockquote p,
.blog_details blockquote .blockquote_inner {
  position: relative;
  padding: 30px 35px;
  background: #ffe9e3;
}

.blog_details .post_content {
  text-align: justify;
}

.blog_details .fulltext {
  font-size: 17px;
  line-height: 28px;
  color: #656a7c;
  font-weight: 500;
}

.blog_details .fulltext .highlight {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--heading-color);
}

.blog_details .fulltext .widget_title {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  text-transform: capitalize;
}

.blog_details p {
  margin-bottom: 20px;
  font-weight: 500;
}

.blog_details ul.point_order {
  padding-left: 55px;
  margin-bottom: 35px;
  list-style-type: none;
}

.blog_details ul.point_order li {
  position: relative;
  padding-left: 35px;
  font-weight: 500;
  line-height: 36px;
  color: var(--heading-color);
}

.blog_details ul.point_order li:before {
  content: "\f382";
  font-family: "Ionicons";
  font-size: 24px;
  position: absolute;
  left: 0;
  color: var(--second-color);
}

.blog_details .post_gallery {
  margin-bottom: 30px;
  text-align: center;
}

.blog_details .post_footer {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.blog_details .post_share {
  display: flex;
  align-items: center;
}

.blog_details .post_share ul.share_list {
  display: flex;
  margin-bottom: 0px;
  padding-left: 0;
}

.blog_details .post_share ul.share_list li {
  font-family: "Nunito Sans", sans-serif;
  list-style-type: none;
  display: inline-block;
}

.blog_details .post_share ul.share_list li a {
  font-size: 12px;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  height: 30px;
  display: inline-block;
  background: var(--second-color);
  color: var(--white-color);
  margin: 0px 5px;
  padding: 0px 15px;
  border-radius: 3px;
  letter-spacing: 3px;
}

.blog_details .post_share ul.share_list li:first-child {
  font-size: 20px;
  font-weight: 700;
  color: var(--heading-color);
  margin-right: 15px;
}

.blog_details .post_share ul.share_list li:first-child a {
  margin-left: 0px;
}

.blog_details .post_share ul.share_list li:last-child a {
  margin-right: 0px;
}

.blog_details .post_share ul.share_list li.facebook a {
  background: #345895;
}

.blog_details .post_share ul.share_list li.twitter a {
  background: #00a0ee;
}

.blog_details .post_share ul.share_list li.dribbble a {
  background: #a99df5;
}

.blog_details .post_share ul.share_list li.instagram a {
  background: #4c57ea;
}

.blog_details .post_share ul.share_list li.linkedin a {
  background: #2867b2;
}

.blog_details .post_share ul.share_list li.pinterest a {
  background: #ff081e;
}

.blog_details .post_tag {
  display: flex;
}

.blog_details .post_tag ul.tag_list {
  display: flex;
  margin-bottom: 0px;
  padding-left: 0;
}

.blog_details .post_tag ul.tag_list li {
  list-style-type: none;
  display: inline-block;
}

.blog_details .post_tag ul.tag_list li a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: lowercase;
  padding: 0px 18px;
}

.blog_details .post_tag ul.tag_list li:nth-child(odd) a {
  color: var(--second-color);
}

.blog_details .post_tag ul.tag_list li:nth-child(even) a {
  color: var(--second-color);
}

.blog_details .post_tag ul.tag_list li:first-child a {
  padding-left: 0px;
}

.blog_details .post_tag ul.tag_list li:last-child a {
  padding-right: 0px;
}

.blog_details .author_div {
  display: flex;
  margin: 40px 0px;
}

.blog_details .author_div .author {
  max-width: 125px;
  min-width: 125px;
  height: 125px;
  margin-right: 30px;
  border-radius: 100%;
  overflow: hidden;
}

.blog_details .author_div .author_name {
  font-size: 22px;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 6px;
}

.blog_details .author_div .author_intro {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  color: #656a7c;
}

.blog_details .author_div .social_media {
  display: flex;
  align-items: center;
}

.blog_details .author_div .social_media ul.social_list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0;
}

.blog_details .author_div .social_media ul.social_list li {
  list-style-type: none;
  display: inline-block;
}

.blog_details .author_div .social_media ul.social_list li a {
  font-size: 16px;
  padding: 0px 16px;
  color: var(--heading-color);
}

.blog_details .author_div .social_media ul.social_list li:first-child a {
  padding-left: 0px;
}

.blog_details .author_div .social_media ul.social_list li:last-child a {
  padding-right: 0px;
}

.blog_details .related_posts {
  margin-bottom: 30px;
}

.blog_details .related_posts .blog_post .post_header {
  margin-top: 25px;
  text-align: left;
}

.blog_details .related_posts .blog_post .post_header .post_title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

/*-------- Comments ------------*/
.comment_sec {
  margin-top: 60px;
  margin-bottom: 75px;
}

.comment_sec .widget_title {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
  color: #0a3f34;
  text-transform: capitalize;
  letter-spacing: 0;
}

.comment_sec ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0px;
}

.comment_sec ul ul {
  padding-left: 90px;
}

.comment_sec ul.comment_area .commenter_div {
  padding-top: 30px;
  padding-bottom: 30px;
}

.comment_sec ul.comment_area .commenter_div .commenter {
  margin-right: 30px;
  min-width: 90px;
  max-width: 90px;
  max-height: 90px;
  float: left;
}

.comment_sec ul.comment_area .commenter_div .comment_block {
  padding-left: 120px;
}

.comment_sec ul.comment_area .commenter_div .comment_block .commenter_name {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 700;
  margin-top: 0px;
  color: var(--heading-color);
  text-transform: capitalize;
}

.comment_sec ul.comment_area .commenter_div .comment_block .commenter_review {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  color: #656a7c;
  margin-top: 7px;
  margin-bottom: 12px;
}

.comment_sec ul.comment_area .commenter_div .comment_block .comment_date {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #aeb6c2;
}

.comment_sec ul.comment_area .commenter_div .comment_block .reply a {
  font-family: "Mulish", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: var(--second-color);
}

.comment_sec .comments-pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0px;
}

.comment_sec .comments-pagination a,
.comment_sec .comments-pagination span {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  background: rgba(255, 169, 3, 0.3);
  color: var(--second-color);
  border: 1px solid transparent;
  border-radius: 4px;
  margin-right: 10px;
}

.comment_sec .comments-pagination a:hover,
.comment_sec .comments-pagination a.current,
.comment_sec .comments-pagination span:hover,
.comment_sec .comments-pagination span.current {
  background: var(--second-color);
  color: var(--white-color);
}

.makeacomment .widget_title {
  font-size: 24px;
  line-height: 44px;
  color: #0a3f34;
}

.makeacomment .contact_form {
  margin-top: 20px;
}

.makeacomment .contact_form .form-group {
  margin-bottom: 20px;
}

.makeacomment .contact_form .comment-notes {
  color: var(--heading-color);
  margin-bottom: 20px;
}

.makeacomment .contact_form ::-webkit-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.makeacomment .contact_form ::-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.makeacomment .contact_form :-ms-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.makeacomment .contact_form :-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.makeacomment .contact_form :placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.read_more a {
  border-radius: 0;
}

.widget_title {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: var(--text-heading-color);
  text-align: left;
  letter-spacing: 2px;
  margin-top: 0px;
  margin-bottom: 30px;
}

.widget_title .title_line {
  display: block;
  width: 50px;
  height: 2px;
  background: var(--second-color);
  margin-top: 10px;
}

.blog_post2 .post_content {
  border-color: transparent;
}

/*=========== Footer =========*/
.pd_0 {
  padding: 0px;
}

.pd_lr_65 {
  padding-left: 65px;
  padding-right: 65px;
}

.footer {
  position: relative;
  /*-- Footer Nav --*/
  /*-- Address --*/
  /*-- Most Read --*/
}

.footer .side_footer_social .bottom_social {
  justify-content: start;
}

.footer .footer_above {
  background: url("../../../public/images/footer_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 90px;
  background-color: #113022;
}

@media (max-width: 394px) {
  .footer .footer_above {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.footer .footer_above .subscribe {
  margin-right: 35px;
}

.footer .footer_above2 {
  padding-top: 100px;
  padding-bottom: 65px;
  background-color: #113022;
}

.footer .footer_above2 .footer_widget2 .logo {
  margin-bottom: 20px;
}

.footer .footer_above2 .footer_widget2 .side_footer_social {
  margin-top: 25px;
}

.footer .footer_above2 .footer_widget2 .widget_title {
  line-height: 52px;
  margin-bottom: 15px;
}

.footer .footer_above2 .footer_contact {
  background: #173823;
  padding: 25px 45px;
  margin-bottom: 60px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #293262;
}

.footer .footer_above2 .footer_contact .footer_contact_inner {
  display: flex;
  align-items: center;
  position: relative;
}

.footer .footer_above2 .footer_contact .footer_contact_inner::before {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  right: -32px;
  background: #293262;
}

.footer
  .footer_above2
  .footer_contact
  .footer_contact_inner:last-child::before {
  content: none;
}

.footer .footer_above2 .footer_contact .footer_contact_inner .contact_icon {
  width: 70px;
  height: 70px;
  font-size: 40px;
  background: #293262;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 20px;
}

.footer .footer_above2 .footer_contact .footer_contact_inner .contact_icon i {
  color: var(--second-color);
}

.footer
  .footer_above2
  .footer_contact
  .footer_contact_inner
  .contact_content
  h6 {
  color: var(--white-color);
  font-size: 14px;
  margin-bottom: 5px;
}

.footer
  .footer_above2
  .footer_contact
  .footer_contact_inner
  .contact_content
  p {
  color: #aeb6c2;
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 600;
}

.footer
  .footer_above2
  .footer_contact
  .footer_contact_inner
  .contact_content
  p
  span {
  color: var(--second-color);
}

.footer p {
  color: #cacfeb;
  font-size: 14px;
  margin-bottom: 20px;
}

.footer ul {
  padding: 0;
  margin-bottom: 0px;
  list-style-type: none;
}

.footer ul li {
  list-style-type: none;
  color: #cacfeb;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
}

.footer ul li > i {
  color: var(--second-color);
  margin-right: 10px;
}

.footer .widget_title {
  font-family: "Mulish", sans-serif;
  position: relative;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: var(--white-color);
  text-align: left;
  margin-bottom: 30px;
}

.footer .widget_title .title_line {
  display: block;
  width: 65px;
  height: 2px;
  background: var(--second-color);
  margin-top: 15px;
}

.footer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.footer .subscribe {
  position: relative;
}

.footer .subscribe input {
  border-radius: 0;
  height: 62px;
  color: var(--body-color);
  border: 0;
}

.footer .subscribe button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 12px;
  color: var(--white-color);
  background: var(--second-color);
  padding: 12px 20px;
  border: 0;
  border-radius: 0;
  z-index: 9;
}

.footer .footer_nav {
  display: flex;
  justify-content: space-between;
}

.footer .footer_nav li {
  position: relative;
}

.footer .footer_nav li:first-child {
  padding-top: 0px;
}

.footer .footer_nav li a {
  font-family: "Mulish", sans-serif;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #cacfeb;
  display: block;
  padding-left: 0px;
  text-decoration: none;
  letter-spacing: -0.2px;
}

.footer .footer_nav li a:hover {
  color: var(--second-color);
}

.footer .footer_contact {
  position: relative;
  margin-top: 0px;
}

.footer .footer_contact p {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: var(--white-color);
  margin-top: 30px;
}

.footer .footer_contact .header {
  background: none;
  width: 250px;
  border: 1px dotted var(--second-color);
  margin-top: 20px;
}

.footer .contact_info {
  padding: 85px 50px 50px 50px;
}

.footer .contact_info h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: var(--white-color);
  margin: 15px 0px;
}

.footer .contact_info .phone {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  color: var(--white-color);
  line-height: 32px;
  padding: 30px 30px;
  margin-top: 25px;
  border: 1px dashed var(--second-color);
  word-break: break-word;
}

.footer .contact_info .phone > * {
  position: relative;
  text-align: left;
}

.footer .contact_info .phone img {
  margin-right: 15px;
}

.footer .contact_info .phone span {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.footer .most_read li {
  margin-bottom: 10px;
}

.footer .most_read_item {
  display: flex;
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #373747;
}

.footer .most_read_item:last-child {
  padding-bottom: 0px;
  margin-bottom: 0px;
  border-bottom: 0px solid #373747;
}

.footer .most_read_item .most_read_image {
  min-width: 80px;
  width: 80px;
  margin-right: 20px;
}

.footer .most_read_item h5 {
  font-size: 20px;
}

.footer .most_read_item h5 a {
  color: var(--white-color);
}

.footer .most_read_item h5:hover a {
  color: var(--second-color);
}

.footer .most_read_item h6 {
  font-size: 14px;
  color: #c5d1e7;
  margin-top: 12px;
}

.footer .footer_bottom {
  background: #fff;
  padding: 15px 30px;
}

.footer .footer_bottom .header {
  background: none;
}

.footer .footer_bottom .header .header_social .top_social li {
  width: auto;
}

.footer .footer_bottom .header .header_social .top_social li a i {
  margin-right: 0;
  line-height: 2.5;
}

.footer .footer_bottom .footer_bottom_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  color: #fff;
}

.footer .footer_bottom .footer_bottom_inner .footer_nav_bottom ul {
  display: flex;
}

.footer .footer_bottom .footer_bottom_inner .footer_nav_bottom ul li {
  margin-bottom: 0;
}

.footer
  .footer_bottom
  .footer_bottom_inner
  .footer_nav_bottom
  ul
  li:first-child {
  margin-right: 20px;
}

.footer .footer_bottom .footer_bottom_inner .copyright {
  margin: 0 auto;
}

.footer .footer_bottom .footer_bottom_inner .copyright p {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.2px;
  text-transform: capitalize;
  color: #173823;
  margin-bottom: 0;
}

.footer .footer_bottom .footer_bottom_inner .copyright p a {
  text-transform: lowercase;
}

.footer .footer_bottom .footer_bottom_inner .footer_nav_bottom a {
  color: #99abbd;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.footer .footer_bottom .footer_bottom_inner .totop i {
  color: #0a3f34;
  font-weight: bolder;
}

.footer .footer_bottom .footer_bottom_inner .totop {
  position: relative;
  text-align: right;
  margin-left: 25px;
}

.footer .footer_bottom .footer_bottom_inner .totop a {
  width: 54px;
  height: 54px;
  background: var(--second-color);
  color: var(--white-color);
  display: inline-block;
  text-align: center;
  line-height: 54px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px var(--second-color);
}

.footer .footer_bottom.footer_bottom_2 .footer_bottom_inner {
  justify-content: space-between;
}

.footer .footer_bottom.footer_bottom_2 .footer_bottom_inner .copyright {
  margin: unset;
}

.footer .footer_bottom.footer_bottom_2 .footer_bottom_inner .footer_nav_bottom {
  margin-left: auto;
}

.footer .footer_social {
  text-align: center;
}

.footer .footer_social .bottom_social {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0px;
}

.footer .footer_social .bottom_social li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  padding: 0px 8px;
}

.footer .footer_social .bottom_social li:first-child {
  padding-left: 0px;
}

.footer .footer_social .bottom_social li:last-child {
  padding-right: 0px;
}

.footer .footer_social .bottom_social li a {
  position: relative;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  background: #2f2f3e;
  color: var(--white-color);
  display: block;
  outline: none;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 100%;
}

.footer .footer_social .bottom_social li a:hover {
  color: var(--second-color);
}

.footer .footer_social_2 .bottom_social {
  display: flex;
  align-items: center;
  padding: 0px;
  margin-bottom: 0px;
}

.footer .footer_social_2 .bottom_social li {
  list-style: none;
  display: inline-block;
  position: relative;
  padding: 0px 15px;
}

.footer .footer_social_2 .bottom_social li:first-child {
  padding-left: 0px;
}

.footer .footer_social_2 .bottom_social li:last-child {
  padding-right: 0px;
}

.footer .footer_social_2 .bottom_social li a {
  position: relative;
  overflow: hidden;
  font-size: 16px;
  text-align: center;
  color: #cbd2d9;
  display: block;
  outline: none;
  transition: all 0.5s ease;
}

.footer .footer_social_2 .bottom_social li a:hover {
  color: var(--white-color);
}

.footer .widget_info {
  margin-top: 20px;
  margin-bottom: 45px;
}

.footer .widget_info p {
  color: #c5d1e7;
  font-size: 17px;
  line-height: 28px;
}

.side_contact_info {
  padding: 50px 40px 20px 40px;
}

.side_contact_info h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: var(--white-color);
  margin: 15px 0px;
}

.side_contact_info .phone {
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  color: var(--white-color);
  line-height: 32px;
  padding: 16px 30px;
  margin-top: 25px;
  border: 1px dashed var(--second-color);
  word-break: break-word;
}

.side_contact_info .phone > * {
  position: relative;
  text-align: left;
}

.side_contact_info .phone img {
  margin-right: 15px;
}

.side_contact_info .phone span {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.side_footer_social {
  text-align: center;
  margin-top: 40px;
}

.side_footer_social .bottom_social {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  padding: 0px;
  margin-bottom: 0px;
}

.side_footer_social .bottom_social li {
  list-style: none;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  padding: 0px 5px;
}

.side_footer_social .bottom_social li:first-child {
  padding-left: 0px;
}

.side_footer_social .bottom_social li:last-child {
  padding-right: 0px;
}

.side_footer_social .bottom_social li a {
  position: relative;
  overflow: hidden;
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: #0a3f34;
  display: block;
  outline: none;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 100%;
}

.side_footer_social .bottom_social li a:hover {
  color: var(--second-color);
}

@media screen and (max-width: 991px) {
  .footer .footer_contact {
    padding-left: 0px;
  }

  .footer .footer_links {
    padding-left: 0px;
  }

  .footer .footer_widget {
    margin: 20px 0px;
  }

  .footer .footer_social {
    text-align: center;
  }
}

.footer_widget_padding {
  padding-right: 42px;
}

.logo a img {
  width: 100%;
  max-width: 153px;
}

/*---------- Funfacts ------------*/
.funfacts {
  padding-top: 100px;
  background-image: url(../../../public/images/funfact/bg3.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.funfacts .fun_bottom {
  padding-bottom: 100px;
  position: relative;
  z-index: 99;
}

@media (max-width: 394px) {
  .funfacts {
    padding-top: 70px;
  }
  .funfacts .fun_bottom {
    padding-bottom: 70px;
  }
}

.funfacts::before {
  position: absolute;
  content: "";
  background: rgb(0 92 72 / 90%);
  width: 100%;
  height: 100%;
  top: 0;
}

.funfacts::after {
  position: absolute;
  content: "";
  background: url(../../../public/images/funfact/fun_frame.png);
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: bottom right;
}

.funfacts .section_header {
  padding-right: 120px;
  position: relative;
  z-index: 99;
}

.funfacts .section_header .section_title {
  color: var(--white-color);
}

.funfacts .section_header .section_desc {
  color: #c5d1e7;
}

.funfacts .man_img {
  display: flex;
  align-items: flex-end;
  height: 100%;
  position: relative;
  z-index: 9;
}

.funfacts_2 {
  position: relative;
  z-index: 1;
  margin-top: 85px;
  margin-bottom: -100px;
  padding: 50px;
  border-radius: 5px;
}

.funfacts_2 .funbox1 {
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  margin: 0;
  padding: 15px 0px;
}

.funbox1 {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  padding: 40px 28px 40px 45px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  transition: all 0.5s ease-in;
}

.funbox1:hover {
  background: var(--second-color);
  border: 1px solid var(--second-color);
}

.funbox1 .fun_img {
  margin-right: 20px;
}

.funbox1 .fun_content span {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  color: var(--white-color);
}

.funbox1 .fun_content p {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: var(--white-color);
}

.funfacts2_bg {
  background-image: url(../../../public/images/funfact/bg4.png);
  background-repeat: no-repeat;
  position: relative;
  margin-bottom: -100px;
  padding: 55px 0;
  display: flex;
  background-size: cover;
  width: 100%;
  justify-content: space-around;
  background-color: var(--white-color);
  border-radius: 20px;
  box-shadow: 0px 30px 20px 10px rgba(0, 42, 92, 0.2);
}

.funfacts3_bg_img {
  background-image: url(../../../public/images/funfact/bg1.png);
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
}

.funfacts3_bg {
  padding: 55px 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.funfacts3_bg .funbox2 .fun_content h1 {
  color: var(--white-color);
}

.funfacts3_bg .funbox2 .fun_content p {
  color: var(--white-color);
  font-weight: 500;
  font-size: 17px;
  line-height: 28px;
}

.funbox2 {
  display: flex;
  align-items: center;
  position: relative;
}

.funbox2::before {
  content: "";
  position: absolute;
  width: 1px;
  top: 0;
  bottom: 0;
  right: -85px;
  background: #aeb6c2;
}

.funbox2:last-child::before {
  content: none;
}

.funbox2 .fun_img {
  width: 78px;
  height: 78px;
  background: var(--second-color);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.funbox2 .fun_content h1 {
  color: var(--heading-color);
  font-weight: 800;
  font-size: 44px;
  line-height: 54px;
}

.funbox2 .fun_content p {
  color: var(--heading-color);
}

/*-- Slider 1 --*/
.theme_slider_1 {
  position: relative;
}

.theme_slider_1 .slider {
  position: relative;
  background-image: url("../../../public/images/slider/bg51.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes sliderobject-move1 {
  0% {
    top: -120px;
  }
  100% {
    top: 120px;
  }
}

@keyframes sliderobject-move2 {
  0% {
    right: 0px;
    top: 0px;
  }
  50% {
    right: -50px;
    top: -50px;
  }
  100% {
    right: 0px;
    top: 0px;
  }
}

.theme_slider_1 .slider .slide_content {
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 120px;
  color: var(--white-color);
}

@media (max-width: 394px) {
  .theme_slider_1 .slider .slide_content {
    padding-top: 30px;
  }
}

@media (max-width: 394px) {
  .theme_slider_1 .slider .slide_content {
    padding-bottom: 40px;
  }
}

.theme_slider_1 .slider .slide_content span {
  color: var(--second-color);
  margin-left: 5px;
}

.theme_slider_1 .slider .slide_content .sub_heading {
  font-family: "Nunito Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.theme_slider_1 .slider .slide_content .heading {
  font-size: 70px;
  font-weight: 700;
  line-height: 88px;
  margin: 20px 0px;
}

.theme_slider_1 .slider .slide_content .desc {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  border-left: 3px solid var(--second-color);
  padding-left: 20px;
}

.theme_slider_1 .slider .slider_button {
  margin-top: 35px;
}

.theme_slider_1 .slider .slider_button .button {
  border: none;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-right: 30px;
  text-transform: capitalize;
}

.theme_slider_1 .slider .slide_content_img {
  position: absolute;
  bottom: 0;
}

@keyframes sliderbg-move1 {
  0% {
    width: 0%;
  }
  70% {
    width: 70%;
  }
  100% {
    width: 57%;
  }
}

/*-- Slider 2 --*/
.theme_slider_2 {
  position: relative;
  --button-width: 44px;
  --button-height: 44px;
}

.theme_slider_2 .swiper-slide:nth-child(1) .slider {
  background-image: url("../../../public/images/slider/bg1.jpg");
}

.theme_slider_2 .swiper-slide:nth-child(2) .slider {
  background-image: url("../../../public/images/slider/bg2.jpg");
}

.theme_slider_2 .floating_object {
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .slider {
  background: #eff3fb;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 270px;
  padding-bottom: 200px;
}

.theme_slider_2 .slider .slide_content {
  position: relative;
  z-index: 1;
  color: var(--white-color);
}

.theme_slider_2 .slider .slide_content span {
  color: var(--second-color);
  margin-left: 5px;
}

.theme_slider_2 .slider .slide_content .sub_heading {
  font-size: 20px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 5px;
  display: inline-flex;
  line-height: 26px;
  color: var(--second-color);
  letter-spacing: 2px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .slider .slide_content .heading {
  font-size: 52px;
  font-weight: 700;
  line-height: 70px;
  margin: 22px 0px;
  color: var(--heading-color);
  opacity: 0;
  visibility: hidden;
  transform: skew(50deg, 0deg);
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .slider .slide_content .desc {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #656a7c;
  opacity: 0;
  visibility: hidden;
  transform: skew(50deg, 0deg);
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .slider .slide_content .slider_button {
  margin-top: 35px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .slider .slide_content .slider_button .button {
  border: none;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0px;
  margin-right: 30px;
  text-transform: capitalize;
}

.theme_slider_2 .slider .slide_content_img {
  position: absolute;
  bottom: 0;
}

.theme_slider_2 .slider .slider_image {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.theme_slider_2 .swiper-pagination {
  bottom: auto;
  top: 50%;
  left: 40px;
  display: inline-grid;
  width: auto;
}

.theme_slider_2
  .swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet,
.theme_slider_2
  .swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 5px 0px;
}

.theme_slider_2 .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  background-color: var(--primary-color);
  opacity: 1;
}

.theme_slider_2 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--second-color);
}

.theme_slider_2 .swiper-navigation {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: var(--button-width);
  height: calc(var(--button-height) * 2);
  overflow: hidden;
  z-index: 9;
  transition: all 1s ease-in-out;
}

.theme_slider_2 .swiper-navigation .swiper-button-prev,
.theme_slider_2 .swiper-navigation .swiper-rtl .swiper-button-next {
  left: auto;
  right: 0;
}

.theme_slider_2 .swiper-navigation .swiper-button-next,
.theme_slider_2 .swiper-navigation .swiper-rtl .swiper-button-prev {
  right: 0;
  left: auto;
}

.theme_slider_2 .swiper-navigation .swiper-button-next,
.theme_slider_2 .swiper-navigation .swiper-button-prev {
  color: var(--second-color);
  background: var(--primary-color);
  position: absolute;
  top: 100%;
  width: var(--button-width);
  height: var(--button-height);
  margin-top: 0px;
  transition: all 0.5s ease-in-out;
}

.theme_slider_2 .swiper-navigation .swiper-button-next:hover,
.theme_slider_2 .swiper-navigation .swiper-button-prev:hover {
  background: var(--second-color);
  color: var(--white-color);
}

.theme_slider_2 .swiper-navigation .swiper-button-next.swiper-button-disabled,
.theme_slider_2 .swiper-navigation .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
  background: rgba(0, 50, 111, 0.85);
}

.theme_slider_2 .swiper-navigation .swiper-button-next:after,
.theme_slider_2 .swiper-navigation .swiper-button-prev:after {
  font-size: 20px;
}

.theme_slider_2:hover .swiper-navigation .swiper-button-prev {
  top: 0;
}

.theme_slider_2:hover .swiper-navigation .swiper-button-next {
  top: var(--button-height);
}

.theme_slider_2 .swiper-slide-active .slider .slide_content .sub_heading {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 0.75s;
}

.theme_slider_2 .swiper-slide-active .slider .slide_content .heading {
  opacity: 1;
  visibility: visible;
  transform: skew(0deg, 0deg);
  transition-delay: 1.25s;
}

.theme_slider_2 .swiper-slide-active .slider .slide_content .desc {
  opacity: 1;
  visibility: visible;
  transform: skew(0deg, 0deg);
  transition-delay: 1.75s;
}

.theme_slider_2 .swiper-slide-active .slider .slide_content .slider_button {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition-delay: 2.25s;
}

.theme_slider_2 .swiper-slide-active .floating_object_1 {
  opacity: 1;
  visibility: visible;
  transition-delay: 1s;
}

.theme_slider_2 .swiper-slide-active .floating_object_2 {
  opacity: 1;
  visibility: visible;
  transition-delay: 1.25s;
}

.theme_slider_2 .swiper-slide-active .floating_object_3 {
  opacity: 1;
  visibility: visible;
  transition-delay: 1.5s;
}

.theme_slider_2 .swiper-slide-active .floating_object_4 {
  opacity: 1;
  visibility: visible;
  transition-delay: 1.75s;
}

.theme_slider_2 .swiper-slide-active .floating_object_5 {
  opacity: 1;
  visibility: visible;
  transition-delay: 2s;
}

.theme_slider_2 .swiper-slide-active .floating_object_6 {
  opacity: 1;
  visibility: visible;
  transition-delay: 2.25s;
}

.theme_slider_2 .swiper-slide-active .avatar-wrapper {
  opacity: 1;
  visibility: visible;
  transition-delay: 1.5s;
  transform: translate(-50%, 0);
}

.avatar-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  background: #ffffff;
  border-radius: 15px;
  padding: 25px 25px;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translate(-50%, 50px);
  z-index: 1;
  width: 100%;
  max-width: 465px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.avatar-wrapper .avatar-group {
  display: inline-flex;
  align-items: center;
}

.avatar-wrapper .avatar-group .avatar {
  width: 50px;
  height: 50px;
}

.avatar-wrapper .avatar-group .avatar:hover {
  z-index: 2;
}

.avatar-wrapper .avatar-group .avatar .avatar-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  font-size: 30px;
  color: #ffa903;
  background: #00326f;
  font-weight: 700;
  cursor: pointer;
}

.avatar-wrapper .avatar-group .avatar + .avatar {
  margin-left: -10px;
}

.avatar-wrapper p {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-color);
}

.avatar-wrapper p span {
  color: var(--second-color);
}

/*------- Team -------*/
.team .slick-list {
  margin: 0px -15px;
}

.team .slick-slide {
  outline: none;
}

.team .team_slick .slick-next {
  background: #f2f7f7;
  color: #6e7a7a;
  width: 25px;
  height: 25px;
  right: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  top: unset;
}

.teambox {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}

.teambox img {
  width: 100%;
}

.teambox:hover .teambox_inner {
  left: 0;
  transform: rotate3d(0, 1, 0, 0deg);
}

.teambox .teambox_inner {
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  padding: 45px;
  background: rgba(0, 42, 92, 0.9);
  transition: all 0.8s ease;
  transform: rotate3d(0, 1, 0, 90deg);
}

.teambox .teambox_inner .teambox_intro {
  width: 100%;
  height: 85%;
  display: flex;
  align-items: flex-end;
}

.teambox .teambox_inner .teambox_intro .team_flex h5 {
  font-size: 24px;
  font-weight: 700;
  color: var(--second-color);
  margin-top: 12px;
  margin-bottom: 0px;
  line-height: 32px;
}

.teambox .teambox_inner .teambox_intro .team_flex h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: #cacfeb;
  margin-bottom: 0px;
  letter-spacing: 5px;
  line-height: 30px;
}

.teambox .teambox_inner .teambox_intro .team_flex.team_size h5 {
  font-size: 20px;
}

.teambox .teambox_inner .teambox_intro .team_flex.team_size h6 {
  font-size: 13px;
}

.teambox .teambox_inner .team_social {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 50px;
  height: 50px;
  transition: height 0.2s linear;
}

.teambox .teambox_inner .team_social:hover {
  height: 260px;
}

.teambox .teambox_inner .team_social .share {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  color: var(--white-color);
  display: block;
  outline: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: var(--second-color);
  cursor: pointer;
  border-radius: 5px;
}

.teambox .teambox_inner .team_social .share:hover {
  color: var(--white-color);
}

.teambox .teambox_inner .team_social ul {
  padding: 0px;
  margin-bottom: 0px;
}

.teambox .teambox_inner .team_social ul li {
  list-style: none;
  display: block;
  position: relative;
  text-transform: uppercase;
  padding: 0px 0px;
}

.teambox .teambox_inner .team_social ul li:first-child {
  padding-top: 0px;
}

.teambox .teambox_inner .team_social ul li:last-child {
  padding-bottom: 0px;
}

.teambox .teambox_inner .team_social ul li a {
  position: relative;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  color: var(--white-color);
  display: block;
  outline: none;
  width: 50px;
  height: 50px;
  line-height: 50px;
  transition: all 0.5s ease;
}

.teambox .teambox_inner .team_social ul li a:hover {
  color: var(--second-color);
}

.team_construction {
  position: relative;
}

.team_construction .team_construction_inner {
  margin-bottom: 30px;
}

.team_construction .team_construction_inner > a {
  position: relative;
  display: block;
}

.team_construction .team_construction_inner > a::before {
  content: "";
  position: absolute;
  background: var(--heading-color);
  opacity: 0.7;
  left: 0;
  top: 0;
  z-index: 1;
  transition: transform 0.5s ease-in;
  height: 100%;
  width: 100%;
  transform: scaleY(0);
  transform-origin: bottom;
  pointer-events: none;
}

.team_construction .team_construction_inner .team-box__info {
  padding: 28px 0 4px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  text-align: center;
  transition: all 0.3s ease-in-out;
  width: 80%;
  margin: -60px auto 0 auto;
}

.team_construction .team_construction_inner .team-box__info a {
  color: #042650;
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.team_construction .team_construction_inner .team-box__info p {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
}

.team_construction:hover .team_construction_inner a::before {
  transform: scaleY(1);
}

.team_construction:hover .team_construction_inner .team-box__info {
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(0px);
}

.team_construction:hover .team_hover_content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.owl_team .team_construction_inner {
  margin-bottom: 0px;
}

.owl_team .owl-item.active.center .team_construction_inner a::before {
  transform: scaleY(1);
}

.owl_team .owl-item.active.center .team_construction_inner .team-box__info {
  opacity: 0;
}

.owl_team .owl-item.active.center .team_hover_content {
  opacity: 1;
  visibility: visible;
  bottom: 0;
}

.team_construction_inner > a > img {
  transition: all 0.4s ease;
}

.team_hover_content {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  transition-delay: 0.2s;
  width: 100%;
  padding: 40px 40px 60px 40px;
}

.team_hover_content h2 {
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
}

.team_hover_content p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 17px;
  margin-bottom: 13px;
  line-height: 28px;
}

.speakers-social-lists-simple {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.speakers-social-lists-simple > li {
  display: inline-block;
}

.speakers-social-lists-simple > li:not(:last-child) {
  margin-right: 5px;
}

.speakers-social-lists-simple > li > a {
  color: #ffffff;
  width: 44px;
  height: 44px;
  line-height: 44px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}

.speakers-social-lists-simple > li:hover a {
  background-color: var(--second-color);
}

.grid-1 .element-item {
  width: 100%;
}

.grid-2 .element-item {
  width: 50%;
}

.grid-3 .element-item {
  width: 33.33%;
}

.grid-4 .element-item {
  width: 25%;
}

.grid-5 .element-item {
  width: 20%;
}

.gutter-0 {
  margin: 0px 0px;
}

.gutter-0 .element-item {
  padding: 15px;
}

.gutter-5 {
  margin: 0px -5px;
}

.gutter-5 .element-item {
  padding: 15px;
}

.gutter-10 {
  margin: 0px -10px;
}

.gutter-10 .element-item {
  padding: 15px;
}

.gutter-15 {
  margin: 0px -15px;
}

.gutter-15 .element-item {
  padding: 15px;
}

.project_main .nav {
  justify-content: center;
  margin-bottom: 30px;
  border-bottom: none;
  gap: 15px;
}

.project_main .nav li {
  position: relative;
}

.project_main .nav li .nav-link {
  border: 1px solid #ecf2f7;
  color: #7d879c;
  font-size: 18px;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 22px;
}

.project_main .nav li .nav-link::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--second-color);
  z-index: 9;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.project_main .nav li .nav-link.active {
  background: var(--second-color);
  color: var(--white-color);
}

.project_main .nav li .nav-link.active::before {
  opacity: 1;
}

.project_iso_bg {
  background: var(--heading-color);
  padding: 200px 0 150px 0;
}

.project_iso1 .button-group .button {
  color: #7d879c !important;
  position: relative;
}

.project_iso1 .button-group .button::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid var(--second-color);
  z-index: 9;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.project_iso1 .button-group .button.is-checked {
  color: var(--white-color) !important;
}

.project_iso .button-group.button_color .button {
  color: #7d879c;
}

.project_iso .button-group.button_color .button.is-checked {
  color: var(--white-color);
}

.project_iso .button-group {
  text-align: center;
  margin-bottom: 40px;
}

.project_iso .button-group .button {
  background: transparent;
  border: 1px solid #cacfeb;
  color: #cacfeb;
  font-size: 18px;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  border-radius: 6px;
  padding: 8px 22px;
  min-width: unset;
  margin-right: 15px;
  position: relative;
  overflow: initial;
  line-height: 30px;
}

.project_iso .button-group .button:last-child {
  margin-right: 0;
}

.project_iso .button-group .button::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--second-color);
  z-index: 9;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.project_iso .button.is-checked {
  background: var(--second-color);
  color: var(--white-color);
  border-color: transparent;
}

.project_iso .button.is-checked::before {
  opacity: 1;
}

.box_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box_bottom p {
  color: #ffffff;
  font-size: 18px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  line-height: 30px;
  margin-right: 3px;
}

.box_bottom a {
  text-decoration: underline;
  font-weight: 700;
}

.project_details {
  /*--- Related Posts ---*/
}

.project_details .post_img {
  position: relative;
  margin-bottom: 60px;
}

.project_details .post_img img {
  width: 100%;
}

.project_details .post_header .categ {
  margin-bottom: 15px;
}

.project_details .post_header .categ a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--white-color);
  background: var(--second-color);
  padding: 2px 15px;
  border-radius: 5px;
  display: inline-block;
}

.project_details .post_header .post_title {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  margin: 18px 0px 10px 0px;
  color: var(--heading-color);
}

.project_details .post_header .tags {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: inline-flex;
}

.project_details .post_header .tags a {
  position: relative;
  color: var(--second-color);
  padding: 0px 10px;
}

.project_details .post_header .tags a:first-child {
  padding-left: 0px;
}

.project_details .post_header .tags a:last-child {
  padding-right: 0px;
}

.project_details .post_header .tags a:last-child:after {
  display: none;
}

.project_details .post_header .tags a:after {
  content: "";
  position: absolute;
  right: 0px;
  bottom: 7px;
  width: 2px;
  height: 2px;
  background: #fd4145;
  border-radius: 100%;
}

.project_details .fulltext {
  font-size: 17px;
  line-height: 28px;
  color: #656a7c;
  font-weight: 500;
}

.project_details .fulltext .highlight {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--heading-color);
}

.project_details .fulltext .widget_title {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 100%;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0;
  text-transform: capitalize;
}

.project_details .fulltext .share_tag {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;
}

.project_details .fulltext .share_tag .post_footer {
  width: unset;
}

.project_details .fulltext .share_tag .widget {
  margin-top: 55px !important;
}

.project_details .fulltext .share_tag .widget ul li a {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  font-weight: 600;
}

.project_details .fulltext .margin_o_para {
  margin-bottom: 0;
}

.project_details .fulltext .video_post .ytube_video .ytplay_btn {
  background: var(--heading-color);
  color: var(--second-color);
}

.project_details p {
  margin-bottom: 30px;
}

.project_details ul.point_order {
  padding-left: 55px;
  margin-bottom: 35px;
  list-style-type: none;
}

.project_details ul.point_order li {
  position: relative;
  padding-left: 35px;
  font-weight: 500;
  line-height: 36px;
  color: var(--heading-color);
}

.project_details ul.point_order li:before {
  content: "\f382";
  font-family: "Ionicons";
  font-size: 24px;
  position: absolute;
  left: 0;
  color: var(--second-color);
}

.project_details .post_gallery {
  margin-bottom: 30px;
  text-align: center;
}

.project_details .post_footer {
  display: inline-block;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.project_details .post_share {
  display: flex;
  align-items: center;
}

.project_details .post_share ul.share_list {
  display: flex;
  margin-bottom: 0px;
  padding-left: 0;
}

.project_details .post_share ul.share_list li {
  font-family: "Nunito Sans", sans-serif;
  list-style-type: none;
  display: inline-block;
}

.project_details .post_share ul.share_list li a {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  width: auto;
  height: 30px;
  display: inline-block;
  background: var(--second-color);
  color: var(--white-color);
  margin: 0px 5px;
  padding: 0px 15px;
  border-radius: 3px;
  letter-spacing: 3px;
}

.project_details .post_share ul.share_list li:first-child {
  font-size: 20px;
  font-weight: 700;
  color: var(--heading-color);
  margin-right: 15px;
}

.project_details .post_share ul.share_list li:first-child a {
  margin-left: 0px;
}

.project_details .post_share ul.share_list li:last-child a {
  margin-right: 0px;
}

.project_details .post_share ul.share_list li.facebook a {
  background: #345895;
}

.project_details .post_share ul.share_list li.twitter a {
  background: #00a0ee;
}

.project_details .post_share ul.share_list li.dribbble a {
  background: #a99df5;
}

.project_details .post_share ul.share_list li.instagram a {
  background: #4c57ea;
}

.project_details .post_share ul.share_list li.linkedin a {
  background: #2867b2;
}

.project_details .post_share ul.share_list li.pinterest a {
  background: #ff081e;
}

.project_details .post_share .new_share {
  align-items: center;
  margin-bottom: 30px !important;
}

.project_details .post_share .new_share li a {
  outline: none !important;
  width: 42px !important;
  height: 42px !important;
  line-height: 42px !important;
  border-radius: 100% !important;
  letter-spacing: 0 !important;
}

.project_details .post_share .new_share li:first-child {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #565656 !important;
  margin-right: 15px !important;
  letter-spacing: -0.2px;
  line-height: 24px;
}

.project_details .post_share .new_share li:first-child a {
  margin-left: 0px;
}

.project_details .post_tag {
  display: flex;
}

.project_details .post_tag ul.tag_list {
  display: flex;
  margin-bottom: 0px;
  padding-left: 0;
}

.project_details .post_tag ul.tag_list li {
  list-style-type: none;
  display: inline-block;
}

.project_details .post_tag ul.tag_list li a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-transform: lowercase;
  padding: 0px 18px;
}

.project_details .post_tag ul.tag_list li:nth-child(odd) a {
  color: var(--second-color);
}

.project_details .post_tag ul.tag_list li:nth-child(even) a {
  color: var(--second-color);
}

.project_details .post_tag ul.tag_list li:first-child a {
  padding-left: 0px;
}

.project_details .post_tag ul.tag_list li:last-child a {
  padding-right: 0px;
}

.project_details .author_div {
  display: flex;
  margin: 40px 0px;
}

.project_details .author_div .author {
  max-width: 125px;
  min-width: 125px;
  margin-right: 30px;
  border-radius: 100%;
  overflow: hidden;
}

.project_details .author_div .author_name {
  font-size: 22px;
  font-weight: 700;
  color: var(--heading-color);
  margin-bottom: 6px;
}

.project_details .author_div .author_intro {
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  color: #656a7c;
}

.project_details .author_div .social_media {
  display: flex;
  align-items: center;
}

.project_details .author_div .social_media ul.social_list {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding-left: 0;
}

.project_details .author_div .social_media ul.social_list li {
  list-style-type: none;
  display: inline-block;
}

.project_details .author_div .social_media ul.social_list li a {
  font-size: 16px;
  padding: 0px 16px;
  color: var(--heading-color);
}

.project_details .author_div .social_media ul.social_list li:first-child a {
  padding-left: 0px;
}

.project_details .author_div .social_media ul.social_list li:last-child a {
  padding-right: 0px;
}

.project_details .related_posts {
  margin-bottom: 30px;
}

.project_details .related_posts .blog_post .post_header {
  margin-top: 25px;
  text-align: left;
}

.project_details .related_posts .blog_post .post_header .post_title {
  font-size: 22px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.inner_posts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  box-shadow: 2px 3px 20px rgba(0, 42, 92, 0.1);
  margin-top: 40px;
}

.inner_posts .inner-post {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner_posts .inner-post .post_block {
  margin-left: 15px;
}

.inner_posts .inner-post .post_block > a {
  color: #aeb6c2;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 2px;
  font-family: "Nunito Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.inner_posts .inner-post .post_block h4 a {
  color: #aeb6c2;
  font-size: 20px;
  line-height: 26px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  color: #00234b;
  text-transform: capitalize;
}

.inner_posts .inner-post:last-child .post_block {
  margin-left: 0;
  margin-right: 15px;
}

.inner_posts .inner-post:last-child img {
  order: 2;
}

.project_info .project_info_bg .project_info_header {
  background: var(--heading-color);
  color: var(--second-color);
  text-align: center;
  padding: 25px 0;
}

.project_info .project_info_bg .project_info_details_bg {
  padding: 0 35px;
  background: #0a3f34;
}

.project_info .project_info_bg .project_info_details_bg .project_info_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #1c4a81;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details:last-child {
  border-bottom: none;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details
  h4 {
  font-weight: 700;
  line-height: 26px;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details
  h5 {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details
  p {
  color: #cacfeb;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: -0.2px;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details
  ul {
  padding-left: 0;
  margin-bottom: 0;
}

.project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details
  ul
  li {
  display: inline;
  color: var(--second-color);
}

.contact {
  background: url("../../../public/images/bg_con.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 80px 0;
}

.contact::before {
  position: absolute;
  content: "";
  background: var(--heading-color);
  width: 100%;
  height: 100%;
  opacity: 0.8;
  top: 0;
}

.contact .banner {
  margin-bottom: 110px;
}

.contact .banner .banner_content .banner_text {
  padding-right: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.contact .banner .banner_content .banner_text h1 {
  text-transform: capitalize;
}

.contact .contact_side_image {
  display: inline-block;
  margin-left: -50px;
}

.gmapbox .map {
  width: 100%;
  height: 500px;
  background-color: #ffeeee;
}

@media (max-width: 394px) {
  .gmapbox .map {
    width: 100%;
    height: 350px;
    background-color: #ffeeee;
  }
}

.contact-page .gmapbox .map {
  height: 450px;
}

.contact_us_1 {
  background: var(--second-color);
  padding: 75px 75px 100px 75px;
  font-size: 40px;
  line-height: 44px;
}

.contact_us_1 .section_header {
  margin-bottom: 35px;
  padding-right: 0px;
}

.contact_us_1 .section_header .section_sub_title {
  color: var(--white-color);
}

.contact_us_1 .section_header .section_title {
  color: var(--white-color);
  font-size: 40px;
  line-height: 46px;
}

.contact_us_1 .form-group {
  margin-bottom: 20px;
}

.contact_us_1 .comment-notes {
  margin-bottom: 10px;
}

.contact_us_1 select,
.contact_us_1 input[type="text"],
.contact_us_1 input[type="email"],
.contact_us_1 input[type="url"],
.contact_us_1 input[type="password"],
.contact_us_1 input[type="search"],
.contact_us_1 input[type="number"],
.contact_us_1 input[type="tel"],
.contact_us_1 input[type="range"],
.contact_us_1 input[type="date"],
.contact_us_1 input[type="month"],
.contact_us_1 input[type="week"],
.contact_us_1 input[type="time"],
.contact_us_1 input[type="datetime"],
.contact_us_1 input[type="datetime-local"],
.contact_us_1 input[type="color"] {
  height: 55px;
  border-radius: 0;
}

.contact_us_1 .form-group ::-webkit-input-placeholder {
  /* Edge */
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_1 .button {
  background: var(--heading-color);
  color: var(--white-color);
  font-family: "Mulish", sans-serif;
}

.contact_us_1 .button:hover {
  background: transparent;
  color: var(--white-color);
  border-color: var(--white-color);
}

.contact_us_1 ::-webkit-input-placeholder {
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_1 ::-moz-placeholder {
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_1 :-ms-input-placeholder {
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_1 :-moz-placeholder {
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_1 :placeholder {
  color: var(--body-color);
  font-weight: 500;
}

.contact_us_2 {
  padding: 110px 0px 110px 130px;
  box-shadow: -10px 0px 15px rgba(199, 175, 175, 0.2);
}

.contact_us_2 .section_header {
  margin-bottom: 35px;
  padding-right: 0px;
}

.contact_us_2 .form-group {
  margin-bottom: 20px;
}

.contact_us_2 .comment-notes {
  margin-bottom: 10px;
}

.contact_us_2 select,
.contact_us_2 input[type="text"],
.contact_us_2 input[type="email"],
.contact_us_2 input[type="url"],
.contact_us_2 input[type="password"],
.contact_us_2 input[type="search"],
.contact_us_2 input[type="number"],
.contact_us_2 input[type="tel"],
.contact_us_2 input[type="range"],
.contact_us_2 input[type="date"],
.contact_us_2 input[type="month"],
.contact_us_2 input[type="week"],
.contact_us_2 input[type="time"],
.contact_us_2 input[type="datetime"],
.contact_us_2 input[type="datetime-local"],
.contact_us_2 input[type="color"] {
  background: transparent;
  border: 1px solid #ffd2d2;
  color: #a05960;
  height: 55px;
}

.contact_us_2 textarea.form-control {
  height: 120px;
  background: transparent;
  border: 1px solid #ffd2d2;
}

.contact_us_2 .button {
  background: var(--second-color);
  color: var(--white-color);
  border: 1px solid var(--second-color);
}

.contact_us_2 .button:hover {
  background: transparent;
  color: var(--second-color);
}

.contact_us_2 ::-webkit-input-placeholder {
  color: #a05960;
}

.contact_us_2 ::-moz-placeholder {
  color: #a05960;
}

.contact_us_2 :-ms-input-placeholder {
  color: #a05960;
}

.contact_us_2 :-moz-placeholder {
  color: #a05960;
}

.contact_us_2 :placeholder {
  color: #a05960;
}

.keepintouch_1 {
  padding: 0px 0px;
}

.keepintouch_1 .communication {
  display: flex;
  flex-flow: row wrap;
  padding: 35px 35px;
  margin-bottom: 30px;
  transition: all 0.5s ease;
}

.keepintouch_1 .communication:nth-child(1) {
  background: #1e1e2a;
}

.keepintouch_1 .communication:nth-child(2) {
  background: #3f1817;
}

.keepintouch_1 .communication:nth-child(3) {
  background: #ffe9e9;
  margin-bottom: 0px;
}

.keepintouch_1 .communication:nth-child(3) h6 {
  color: #a05960;
}

.keepintouch_1 .communication h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 12px;
  color: var(--second-color);
  transition: all 0.5s ease;
}

.keepintouch_1 .communication h6 {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--white-color);
  transition: all 0.5s ease;
}

.keepintouch_1 .communication .communication_icon {
  position: relative;
  min-width: 50px;
  margin-right: 20px;
  margin-bottom: 25px;
}

.keepintouch_1 .communication .communication_icon img {
  width: auto;
  transition: all 0.5s ease;
}

.keepintouch_1 .communication .communication_icon img.secondary_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
}

.keepintouch_1 .communication .info_body {
  width: 75%;
}

.keepintouch_1 .communication:hover:nth-child(1) {
  background: var(--second-color);
}

.keepintouch_1 .communication:hover:nth-child(2) {
  background: var(--second-color);
}

.keepintouch_1 .communication:hover:nth-child(3) {
  background: var(--second-color);
}

.keepintouch_1 .communication:hover:nth-child(3) h6 {
  color: var(--white-color);
}

.keepintouch_1 .communication:hover h5 {
  color: var(--white-color);
}

.keepintouch_1 .communication:hover h6 {
  color: var(--white-color);
}

.keepintouch_1 .communication:hover .communication_icon img.primary_img {
  opacity: 0;
  visibility: hidden;
}

.keepintouch_1 .communication:hover .communication_icon img.secondary_img {
  opacity: 1;
  visibility: visible;
}

.keepintouch_2 {
  padding: 110px 0px 0px 0px;
}

.keepintouch_2 .communication {
  display: flex;
  margin-bottom: 60px;
  transition: all 0.5s ease;
}

.keepintouch_2 .communication:last-child {
  margin-bottom: 0px;
}

.keepintouch_2 .communication h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 0px;
  margin-bottom: 12px;
  color: var(--dark-color);
  transition: all 0.5s ease;
}

.keepintouch_2 .communication h6 {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: #a05960;
  transition: all 0.5s ease;
}

.keepintouch_2 .communication .communication_icon {
  position: relative;
  min-width: 50px;
  margin-right: 20px;
  margin-bottom: 25px;
}

.keepintouch_2 .communication .communication_icon img {
  width: auto;
  transition: all 0.5s ease;
}

.keepintouch_2 .communication .info_body {
  width: 70%;
}

.contact_inner {
  background: var(--heading-color);
  position: relative;
  padding-top: 110px;
  padding-bottom: 90px;
}

@media (max-width: 394px) {
  .contact_inner {
    padding-top: 0;
  }
}

.contact_inner::before {
  position: absolute;
  content: "";
  top: 0;
  background: url(../../../public/images/c1.webp);
  width: 30%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
}

.contact_inner .contact_us_1 {
  background: transparent;
  padding: 0 0 0 0;
}

.contact_inner .contact_us_1 .button {
  background: var(--second-color);
  transition: all 0.5s ease;
}

.contact_inner .contact_us_1 .button:hover {
  background: transparent;
  border-color: var(--white-color);
}

.contact_inner .contact_us_1 .section_header .section_sub_title {
  color: var(--second-color);
}

.contact_inner .contact_us_1 input[type="text"],
.contact_inner .contact_us_1 input[type="email"] {
  background: #0a3f34;
  border: none;
  color: var(--white-color);
}

.contact_inner .contact_us_1 textarea.form-control {
  background: #0a3f34;
  border: none;
  color: var(--white-color);
}

.contact_inner .contact_us_1 ::-webkit-input-placeholder {
  /* Edge */
  color: #cacfeb;
  font-weight: 500;
}

.keepintouch_3 {
  padding-top: 15px;
}

.keepintouch_3 .communication {
  background: var(--white-color);
  display: flex;
  flex-flow: row wrap;
  padding: 35px;
  margin-bottom: 30px;
  transition: all 0.5s ease;
}

.keepintouch_3 .communication .communication_icon {
  background: #293262;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: var(--second-color);
}

.keepintouch_3 .communication .info_body {
  margin-left: 20px;
}

.keepintouch_3 .communication .info_body span {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--heading-color);
  margin-bottom: 3px;
}

.keepintouch_3 .communication .info_body p {
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  color: var(--body-color);
}

/*
 * Shortcode: sidebar
 * -----------------------------------------------
*/
.sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
  background: var(--white-color);
}

.widget {
  /*margin-bottom: 30px;*/
  border-radius: 8px;
}

.widget:last-child {
  margin-bottom: 0px;
}

.widget ul {
  margin: 0;
  padding: 0;
}

.widget ul li {
  list-style-type: none;
}

.widget .widget_title {
  display: block;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.widget .widget_title .title_line {
  display: block;
  width: 40px;
  height: 1px;
  margin-top: 6px;
}

/*-- Search --*/
.widget_search {
  padding: 0px;
  background: transparent;
}

.sidebar_search {
  text-align: left;
}

.sidebar_search .search_form {
  position: relative;
}

.sidebar_search .search_form .form-control {
  padding: 5px 30px;
  height: 50px;
  border: 1px solid #ececec;
  color: var(--second-color);
  background: #fff;
  border-radius: 0;
}

.sidebar_search .search_form .form-control:focus {
  box-shadow: none;
}

.sidebar_search .search_form .form-control-submit {
  position: absolute;
  top: 1px;
  right: 1px;
  border: 0px solid var(--heading-color);
  background: var(--heading-color);
  padding: 0;
  width: 48px;
  height: 48px;
  font-size: 20px;
  color: var(--white-color);
  cursor: pointer;
}

.sidebar_search .search_form .form-control-submit:focus {
  outline: none;
}

.sidebar_search .search_form ::-webkit-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: var(--body-color);
}

.sidebar_search .search_form ::-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: var(--second-color);
}

.sidebar_search .search_form :-ms-input-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: var(--second-color);
}

.sidebar_search .search_form :-moz-placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: var(--second-color);
}

.sidebar_search .search_form :placeholder {
  font-family: "Mulish", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: var(--second-color);
}

/*-- Author --*/
.sidebar_author {
  position: relative;
}

.sidebar_author img {
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-bottom: 25px;
}

.sidebar_author .intro {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 6px;
  color: var(--body-color);
  font-family: "Nunito Sans", sans-serif;
}

.sidebar_author .author_social {
  margin-top: 20px;
}

.sidebar_author .author_social ul {
  margin: 0px;
  padding: 0;
  display: flex;
  align-items: center;
}

.sidebar_author .author_social ul li {
  list-style-type: none;
  width: auto;
  margin-right: 10px;
  /* Social Color */
}

.sidebar_author .author_social ul li:hover a {
  background: var(--heading-color);
  color: var(--second-color);
}

.sidebar_author .author_social ul li a {
  background: #f2f7f7;
  display: block;
  font-size: 14px;
  text-align: center;
  color: var(--heading-color);
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
}

.sidebar_author .author_social ul li a i {
  min-width: 1em;
  min-height: 1em;
  display: block;
}

.sidebar_author .author_social ul li:last-child {
  margin-right: 0px;
}

.sidebar_author .author_social ul li.facebook a {
  background: #345895;
}

.sidebar_author .author_social ul li.twitter a {
  background: #00a0ee;
}

.sidebar_author .author_social ul li.dribbble a {
  background: #2867b2;
}

.sidebar_author .author_social ul li.linkedin a {
  background: #2867b2;
}

.sidebar_author .author_social ul li.instagram a {
  background: #4c57ea;
}

.sidebar_author .author_social ul li.skype a {
  background: #00aff0;
}

.sidebar_author .author_social ul li.rss a {
  background: #8265ff;
}

.sidebar_author .author_social ul li.pinterest a {
  background: #ff081e;
}

@media screen and (max-width: 991px) {
  .sidebar_author .author_social ul li {
    margin-right: 8px;
  }

  .sidebar_author .author_social ul li a {
    width: 30px;
    height: 30px;
    line-height: 34px;
    font-size: 14px;
  }
}

/*-- Recent Posts --*/
.widget_recent_posts {
  padding-left: 0px;
  padding-right: 0px;
}

.widget_recent_posts ul li {
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid rgba(174, 182, 194, 0.15);
}

.widget_recent_posts ul li:first-child {
  padding-top: 0px;
}

.widget_recent_posts ul li:last-child {
  padding-bottom: 0px;
  border-bottom: 0px solid #ffd8d9;
}

.widget_recent_posts ul li .recent_post_image {
  min-width: 125px;
  width: 125px;
  margin-right: 22px;
}

.widget_recent_posts ul li h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  color: var(--heading-color);
  margin-bottom: 15px;
  word-break: break-word;
}

.widget_recent_posts ul li h5 a {
  color: var(--heading-color);
}

.widget_recent_posts ul li h5 a :hover {
  color: var(--second-color);
}

.widget_recent_posts ul li h6 {
  font-size: 14px;
  font-weight: 500;
  color: #aeb6c2;
}

/*-- popular Posts --*/
.widget_popular_posts {
  padding-left: 0px;
  padding-right: 0px;
}

.widget_popular_posts .widget_title {
  padding-left: 40px;
}

.widget_popular_posts ul li {
  display: flex;
  align-items: center;
  padding: 25px 30px 25px 40px;
  border-bottom: 1px solid #ffd8d9;
}

.widget_popular_posts ul li:first-child {
  padding-top: 0px;
}

.widget_popular_posts ul li:last-child {
  padding-bottom: 0px;
  border-bottom: 0px solid #ffd8d9;
}

.widget_popular_posts ul li .popular_post_image {
  min-width: 125px;
  width: 125px;
  margin-right: 22px;
}

.widget_popular_posts ul li h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #752b32;
  margin-bottom: 15px;
}

.widget_popular_posts ul li h5 a {
  color: #752b32;
}

.widget_popular_posts ul li h5 a :hover {
  color: var(--second-color);
}

.widget_popular_posts ul li h6 {
  font-size: 14px;
  font-weight: 600;
  color: var(--second-color);
}

/*-- Categories --*/
.widget_categories ul li {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: var(--black-color);
  padding: 6px 0px;
  line-height: 24px;
  transition: all 0.5s ease-in-out;
}

.widget_categories ul li:not(.category_item) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.widget_categories ul li:first-child {
  background-image: none;
  padding-top: 0px;
}

.widget_categories ul li:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget_categories ul li:hover {
  color: var(--second-color);
}

.widget_categories ul li a {
  color: var(--body-color);
}

.widget_categories ul li a:hover {
  color: var(--second-color);
}

.widget_categories ul li.active {
  color: var(--second-color);
}

.widget_categories ul li.active a {
  color: var(--second-color);
}

/*-- Tag Cloud --*/
.sidebar_tags ul li {
  list-style-type: none;
  display: inline-block;
  line-height: 24px;
  overflow: hidden;
}

.sidebar_tags ul li:not(:last-child) {
  margin-right: 14px;
}

.sidebar_tags ul li a {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  color: var(--body-color);
}

.sidebar_tags ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 0%;
  height: 1px;
  background: var(--text-heading-color);
  transition: width 0.5s ease;
}

.sidebar_tags ul li a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 99%;
  height: 1px;
  background: var(--body-color);
  transition: all 0.3s ease;
}

.sidebar_tags ul li a:hover {
  color: var(--text-heading-color);
}

.sidebar_tags ul li a:hover::before {
  width: 99%;
}

.sidebar_tags ul li a:hover::after {
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

/*------- FAQ -------*/
.faq {
  padding-bottom: 0px;
}

.images-faq {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.faq_imgbox {
  margin-top: 20px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.accordion {
  margin-bottom: 80px;
}

.accordion .item {
  position: relative;
  border-radius: 5px;
  transition: all 0.7s ease;
}

.accordion .item .accordion_tab {
  position: relative;
  padding: 17px 25px 17px 30px;
  cursor: pointer;
  border-top: 1px solid #ececec;
}

.accordion .item .accordion_tab .accordion_title {
  position: relative;
  font-size: 16px;
  line-height: 30px;
  color: var(--heading-color);
  padding-right: 50px;
  border: none;
}

.accordion .item .accordion_tab .accordion_title span {
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  color: var(--second-color);
  margin-right: 5px;
}

.accordion .item .accordion_tab .accordion_tab_icon {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
  width: 35px;
  height: 35px;
  background: transparent;
  color: #0a3f34;
  font-size: 20px;
  text-align: center;
  line-height: 36px;
  border-radius: 100%;
}

.accordion .item .accordion_tab .accordion_tab_icon .close_icon {
  display: none;
}

.accordion .item .accordion_info {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;
  text-align: justify;
  color: var(--body-color);
  padding: 20px 50px 30px 30px;
  display: none;
  letter-spacing: -0.2px;
}

.accordion .item.active .accordion_tab {
  background: var(--second-color);
}

.accordion .item.active .accordion_tab .accordion_title {
  color: var(--white-color);
}

.accordion .item.active .accordion_tab .accordion_tab_icon {
  background: var(--second-color);
  color: var(--white-color);
  box-shadow: 0px 0px 6px var(--second-color);
}

.accordion .item.active .accordion_tab .accordion_tab_icon .open_icon {
  display: none;
}

.accordion .item.active .accordion_tab .accordion_tab_icon .close_icon {
  display: block;
}

.accordion .item.active .accordion_info {
  display: block;
}

.accordion .item:last-child {
  margin-bottom: 0px;
}

.shop_page {
  padding: 100px 0px;
}

/*
 * Shortcode: Shop Sidebar
 * -----------------------------------------------
*/
.shop_sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
  font-family: "Nunito Sans", sans-serif;
  /*-- Select2 Customize --*/
  /*-- Search --*/
  /*-- Category Dropdown --*/
  /*-- Price Range --*/
  /*-- Price Checkbox --*/
}

.shop_sidebar .widget {
  margin-top: 0px;
  margin-bottom: 45px;
  background: transparent;
  padding: 0px 0px;
  box-shadow: none;
}

.shop_sidebar .widget:last-child {
  margin-bottom: 0px;
}

.shop_sidebar .widget ul {
  margin: 0;
  padding: 0;
}

.shop_sidebar .widget ul li {
  list-style-type: none;
}

.shop_sidebar .widget label {
  font-size: 14px;
  margin-bottom: 0px;
}

.shop_sidebar .select2-container {
  width: 100% !important;
}

.shop_sidebar .select2-container .select2-selection--single {
  height: 56px;
  line-height: 50px;
  border: 1px solid #ededed;
  padding: 5px 30px;
}

.shop_sidebar
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 15px;
  color: #a0a4b4;
  line-height: 44px;
  padding: 0;
}

.shop_sidebar
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 56px;
  position: absolute;
  top: 0px;
  right: 15px;
  width: 20px;
}

.shop_sidebar .select2-dropdown {
  padding: 15px;
}

.shop_sidebar .select2-search--dropdown {
  padding: 0px;
  margin-bottom: 5px;
}

.shop_sidebar .select2-results__option {
  padding: 3px 10px;
  transition: all 0.5s ease-in-out;
}

.shop_sidebar .select2-container--default .select2-results__option--selected,
.shop_sidebar
  .select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--second-color);
  color: var(--white-color);
  transition: all 0.5s ease-in-out;
}

.shop_sidebar .sidebar_search {
  text-align: left;
}

.shop_sidebar .sidebar_search .search_form {
  position: relative;
}

.shop_sidebar .sidebar_search .search_form .form-control {
  padding: 5px 15px;
  height: 48px;
  border: 1px solid #252525;
  border-radius: 0px;
  color: #000000;
  background: #ffffff;
}

.shop_sidebar .sidebar_search .search_form .form-control:focus {
  box-shadow: none;
}

.shop_sidebar .sidebar_search .search_form .form-control-submit {
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid #042650;
  border-radius: 0%;
  padding: 0;
  width: 48px;
  height: 48px;
  color: #ffffff;
  background: #042650;
  cursor: pointer;
}

.shop_sidebar .sidebar_search .search_form .form-control-submit:focus {
  outline: none;
}

.shop_sidebar .sidebar_search .search_form ::-webkit-input-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #565656;
}

.shop_sidebar .sidebar_search .search_form ::-moz-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #565656;
}

.shop_sidebar .sidebar_search .search_form :-ms-input-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #565656;
}

.shop_sidebar .sidebar_search .search_form :-moz-placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #565656;
}

.shop_sidebar .sidebar_search .search_form :placeholder {
  font-size: 13px;
  font-weight: 400;
  color: #565656;
}

.shop_sidebar .widget_categories .category_item_inner {
  position: relative;
  min-height: 1.5rem;
  padding-left: 0;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  flex-direction: unset;
  justify-content: space-between;
}

.shop_sidebar .widget_categories ul li {
  margin-bottom: 4px;
  border-bottom: none;
  padding: 5px 0px;
}

.shop_sidebar .widget_categories ul li ul {
  padding-left: 15px;
}

.shop_sidebar .widget_categories ul li ul li {
  position: relative;
  padding-left: 10px;
}

.shop_sidebar .widget_categories ul li ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 6px;
  height: 1px;
  background: #333333;
}

.shop_sidebar .widget_categories ul li .cat_toggler a {
  font-size: 12px;
}

.shop_sidebar .widget_categories ul li .cat_toggler i {
  display: block;
  transition: all 0.3s ease-in-out;
}

.shop_sidebar .widget_categories ul li .cat_toggler [aria-expanded="true"] i {
  transform: rotate(90deg);
  color: var(--second-color);
}

.shop_sidebar .sidebar_price_filter .range-value {
  margin-top: 10px;
  font-size: 12px;
  color: #565656;
}

.shop_sidebar .sidebar_price_filter .range-value span {
  margin-right: 10px;
}

.shop_sidebar .sidebar_price_filter .range-value input[type="text"] {
  background-color: transparent;
  color: #565656;
  padding: 0px;
  width: 68px;
  height: auto;
  border-width: 0px;
  text-align: right;
  font-size: 12px;
}

.shop_sidebar .sidebar_price_filter .range-bar {
  border: none;
  background: #eeeeee;
  height: 2px;
  width: 100%;
}

.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-range {
  background: #1c1c1c;
}

.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle {
  width: 12px;
  height: 12px;
  border-width: 0px;
  cursor: pointer;
  margin-top: 0px;
  margin-left: -5px;
}

.shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle {
  background: #1c1c1c;
  border-radius: 100%;
}

.shop_sidebar .form-check {
  position: relative;
  min-height: auto;
  padding-left: 0;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.shop_sidebar .form-check label {
  color: #565656;
  line-height: 19px;
}

.shop_sidebar .form-check .form-check-input {
  float: none;
  margin: 0px 7px 0px 0px;
  width: 12px;
  height: 12px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
}

.shop_sidebar .form-check-input:checked {
  background-color: var(--dark-color);
  border-color: var(--dark-color);
  box-shadow: none;
}

.shop_sidebar .form-check-input:focus {
  outline: none;
  box-shadow: none;
}

.shop_sidebar .content_count {
  background: #fff2f0;
  display: inline-block;
  font-size: 10px;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: var(--primary-color);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/*-- Popular Products --*/
.sidebar_popular_products ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar_popular_products ul li .popular_product_image {
  min-width: 85px;
  margin-right: 15px;
}

.sidebar_popular_products ul li .popular_product_content .product_title {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin: 0px 0px 10px 0px;
}

.sidebar_popular_products ul li .popular_product_content .product_title a {
  color: #333333;
}

.sidebar_popular_products ul li .popular_product_content .product_price {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #959595;
}

/*-- ============================================== --*/
/*-- ================ Main Content ================ --*/
/*-- ============================================== --*/
/*-- Filtering Method --*/
.shop_filtering_method {
  justify-content: space-between;
  margin-bottom: 30px;
}

.shop_filtering_method .view_type_wrapper {
  min-width: 300px;
}

.shop_filtering_method .view_type_wrapper .view_type a {
  font-size: 14px;
  color: #c6c6c6;
  display: inline-block;
  text-align: center;
}

.shop_filtering_method .view_type_wrapper .view_type a.active {
  color: var(--second-color);
}

.shop_filtering_method .view_type_wrapper .view_type li:not(:last-child) {
  margin-right: 8px;
}

.shop_filtering_method .view_type_wrapper .showing_results {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #0a3f34;
  margin-left: 20px;
}

.shop_filtering_method .sorting_select {
  position: relative;
  width: 260px;
}

.shop_filtering_method .select2-container .select2-selection--single {
  width: 260px;
  height: 48px;
  line-height: normal;
  border: 1px solid #eeeeee;
  border-radius: 0px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop_filtering_method
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 15px;
  color: #000;
  line-height: 44px;
  padding: 0;
}

.shop_filtering_method
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  position: relative;
  top: auto;
  right: 15px;
  width: auto;
  height: auto;
}

.shop_filtering_method
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: relative;
  top: auto;
  left: auto;
  width: 0;
  height: 5px;
  display: inline-block;
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 0px 0px 0 0px;
  margin-left: 0;
  margin-top: 0;
}

.shop_filtering_method
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  content: "\f123";
  font-family: "Ionicons";
  font-size: 10px;
}

/*------ Product Column ------*/
.product_col_1,
.product_col_2,
.product_col_3,
.product_col_4 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.product_col_1 .product_item,
.product_col_2 .product_item,
.product_col_3 .product_item,
.product_col_4 .product_item {
  position: relative;
  width: 100%;
  min-height: 1px;
  -webkit-box-flex: 0;
  padding-right: 15px;
  padding-left: 15px;
  box-sizing: border-box;
}

.product_col_1 .product_item {
  flex: 0 0 100%;
  max-width: 100%;
}

.product_col_2 .product_item {
  flex: 0 0 50%;
  max-width: 50%;
}

.product_col_3 .product_item {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.product_col_4 .product_item {
  flex: 0 0 25%;
  max-width: 25%;
}

@media screen and (max-width: 991px) {
  .product_col_3 .product_item,
  .product_col_4 .product_item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .product_col_2 .product_item,
  .product_col_3 .product_item,
  .product_col_4 .product_item {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/*----- Product Grid Item ------*/
.product_view_grid .product_item {
  position: relative;
  overflow: hidden;
}

.product_view_grid .product_item .product_thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 30px;
}

.product_view_grid .product_item .product_thumb .product_imagebox {
  position: relative;
  background: var(--white-color);
  text-align: center;
  transition: all 0.8s ease;
}

.product_view_grid .product_item .product_thumb .product_imagebox img {
  width: 100%;
}

.product_view_grid .product_item .product_thumb .product_imagebox .primary_img {
  transition: all 0.8s ease;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_imagebox
  .secondary_img {
  position: absolute;
  left: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.8s ease;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_imagebox
  [class*="badge"] {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 0;
  padding: 0px 0px;
  width: 50px;
  line-height: 24px;
  z-index: 1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.product_view_grid .product_item .product_thumb .product_imagebox .badge-new {
  background-color: #0a3f34;
  color: var(--second-color);
}

.product_view_grid .product_item .product_thumb .product_imagebox .badge-sale {
  background-color: var(--second-color);
  color: var(--white-color);
}

.product_view_grid .product_item .product_thumb .product_imagebox::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--heading-color);
  opacity: 0;
  z-index: 1;
  transition: all 0.8s ease;
}

.product_view_grid .product_item .product_thumb .product_item_inner {
  transition: all 0.5s ease;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .label_text {
  padding: 20px 0px 0px 0px;
  transition: all 0.5s ease;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0px;
  color: var(--text-heading-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: var(--text-heading-color);
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #656a7c;
  padding-bottom: 0px;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 5px;
}

.product_view_grid .product_item .product_thumb .product_item_inner p {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  font-size: 12px;
  color: #6e7a7a;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a {
  color: #6e7a7a;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a:hover {
  color: var(--primary-color);
}

.product_view_grid .product_item .product_thumb .product_item_inner .rating ul {
  display: block;
  padding: 0px 0px;
  margin: 0px 0px;
  line-height: 16px;
}

.product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .rating
  ul
  li {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  color: var(--second-color);
  margin: 0px;
}

.product_view_grid .product_item .product_thumb .cart_button {
  position: absolute;
  width: 100%;
  text-align: center;
  top: -100%;
  transform: translateY(-50%);
  transition: all 0.8s ease;
  z-index: 1;
}

.product_view_grid
  .product_item:hover
  .product_thumb
  .product_imagebox
  .primary_img {
  opacity: 0;
  visibility: hidden;
  transition: all 0.8s ease;
}

.product_view_grid
  .product_item:hover
  .product_thumb
  .product_imagebox
  .secondary_img {
  opacity: 1;
  visibility: visible;
  transition: all 0.8s ease;
}

.product_view_grid
  .product_item:hover
  .product_thumb
  .product_imagebox::before {
  opacity: 0.7;
}

.product_view_grid .product_item:hover .product_thumb .cart_button {
  top: 50%;
}

.product_view_grid.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  color: #042650;
}

.product_view_grid.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-size: 14px;
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 12px;
}

.product_view_grid.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 3px;
}

.product_view_grid.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .rating {
  margin: 6px 0px 10px 0px;
}

.product_view_grid.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .rating
  ul
  li {
  color: var(--second-color);
}

.product_view_grid.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.product_view_grid.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: #6e7a7a;
}

.product_view_grid.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-size: 14px;
  font-weight: 700;
  color: #173823;
}

.product_view_grid.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-size: 16px;
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 12px;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text {
  text-align: center;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  justify-content: center;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .rating {
  margin: 0px 0px 8px 0px;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .rating
  ul
  li {
  font-size: 12px;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #6e7a7a;
}

.product_view_grid.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  margin-top: 5px;
}

.cart_button .button {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  min-width: auto;
  display: inline-block;
  border: none;
  padding: 11px 40px;
  outline: none;
  background: transparent;
  overflow: hidden;
  cursor: pointer;
  backface-visibility: hidden;
  transition: all 0.5s ease;
  color: var(--white-color);
}

.cart_button .button:hover {
  color: var(--primary-color);
}

.cart_button .button:before,
.cart_button .button:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: -1;
  transition: all 0.5s ease;
}

.cart_button .button:before {
  right: 100%;
  top: 0;
  background: var(--white-color);
}

.cart_button .button:hover::before {
  right: 0;
}

.cart_button .button:after {
  left: 0;
  top: 0;
  background: var(--second-color);
}

.cart_button .button:hover::after {
  left: 100%;
}

/*------ Product List Item -------*/
.product_view_list {
  background: #ffffff;
  padding: 30px 40px;
  box-shadow: 0px 0px 30px rgba(148, 148, 148, 0.15);
}

.product_view_list .grid-item:not(:last-child) {
  margin-bottom: 30px;
}

.product_view_list .product_item {
  position: relative;
  overflow: hidden;
  border: 1px solid #ededed;
  padding: 30px;
  margin-bottom: 30px;
  display: none;
}

.product_view_list .product_item .product_thumb {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
}

.product_view_list .product_item .product_thumb .product_imagebox {
  position: relative;
  background: var(--white-color);
  text-align: center;
  margin-right: 30px;
  overflow: hidden;
  width: 230px;
  transition: all 0.8s ease;
}

.product_view_list .product_item .product_thumb .product_imagebox img {
  width: 100%;
  height: 100%;
}

.product_view_list .product_item .product_thumb .product_imagebox .primary_img {
  transition: all 0.8s ease;
}

.product_view_list
  .product_item
  .product_thumb
  .product_imagebox
  .secondary_img {
  position: absolute;
  left: 50%;
  top: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-50%);
  transition: all 0.8s ease;
}

.product_view_list
  .product_item
  .product_thumb
  .product_imagebox
  [class*="badge"] {
  position: absolute;
  top: 15px;
  left: 15px;
  border-radius: 0;
  padding: 0px 0px;
  width: 50px;
  line-height: 24px;
  z-index: 1;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0px;
}

.product_view_list .product_item .product_thumb .product_imagebox .badge-new {
  background-color: #0a3f34;
  color: var(--second-color);
}

.product_view_list .product_item .product_thumb .product_imagebox .badge-sale {
  background-color: var(--second-color);
  color: var(--white-color);
}

.product_view_list .product_item .product_thumb .product_item_inner {
  transition: all 0.5s ease;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .label_text {
  transition: all 0.5s ease;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0px;
  color: var(--text-heading-color);
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: var(--text-heading-color);
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #656a7c;
  padding-bottom: 0px;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 5px;
}

.product_view_list .product_item .product_thumb .product_item_inner p {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  font-size: 12px;
  color: #6e7a7a;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a {
  color: #6e7a7a;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a:hover {
  color: var(--primary-color);
}

.product_view_list .product_item .product_thumb .product_item_inner .rating {
  margin: 10px 0px;
}

.product_view_list .product_item .product_thumb .product_item_inner .rating ul {
  display: block;
  padding: 0px 0px;
  margin: 0px 0px;
  line-height: 16px;
}

.product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .rating
  ul
  li {
  display: inline-block;
  font-size: 14px;
  line-height: 14px;
  color: var(--second-color);
  margin: 0px;
}

.product_view_list .product_item .product_thumb .cart_button {
  margin-top: 15px;
}

.product_view_list .product_item .product_thumb .cart_button .button {
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
  padding: 10px 25px;
  z-index: 1;
}

.product_view_list .product_item .product_thumb .cart_button .button:hover {
  color: var(--second-color);
}

.product_view_list .product_item .product_thumb .cart_button .button:before {
  background: var(--primary-color);
}

.product_view_list
  .product_item:hover
  .product_thumb
  .product_imagebox
  .primary_img {
  opacity: 0;
  visibility: hidden;
  transition: all 0.8s ease;
}

.product_view_list
  .product_item:hover
  .product_thumb
  .product_imagebox
  .secondary_img {
  opacity: 1;
  visibility: visible;
  transition: all 0.8s ease;
}

.product_view_list.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  color: #042650;
}

.product_view_list.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-size: 14px;
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 12px;
}

.product_view_list.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  font-size: 14px;
  text-transform: capitalize;
  margin-bottom: 3px;
}

.product_view_list.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .rating {
  margin: 6px 0px 10px 0px;
}

.product_view_list.type_2
  .product_item
  .product_thumb
  .product_item_inner
  .rating
  ul
  li {
  color: var(--second-color);
}

.product_view_list.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.product_view_list.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: #6e7a7a;
}

.product_view_list.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-size: 14px;
  font-weight: 700;
  color: #173823;
}

.product_view_list.type_3
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  font-size: 16px;
  font-weight: 400;
  color: #c6c6c6;
  margin-right: 12px;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text {
  text-align: center;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  justify-content: center;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .rating {
  margin: 0px 0px 8px 0px;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .rating
  ul
  li {
  font-size: 12px;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #6e7a7a;
}

.product_view_list.type_4
  .product_item
  .product_thumb
  .product_item_inner
  .product_category {
  margin-top: 5px;
}

.product_view_list .load_more {
  width: 100%;
}

/*----- Single Product -----*/
.product_left .product_zoom {
  display: flex;
  align-items: flex-start;
}

.product_left .product_zoom .product_zoom_button_group {
  padding: 0px;
  margin: 0px;
  list-style: none;
  max-height: 560px;
  overflow-y: scroll;
  display: inline-block;
  padding-right: 23px;
}

.product_left .product_zoom .product_zoom_button_group::-webkit-scrollbar {
  width: 0px;
  height: 4px;
}

.product_left
  .product_zoom
  .product_zoom_button_group::-webkit-scrollbar-track {
  background: var(--primary-color);
}

.product_left
  .product_zoom
  .product_zoom_button_group::-webkit-scrollbar-thumb {
  background: var(--second-color);
}

.product_left .product_zoom .product_zoom_button_group li {
  text-align: left;
  margin: 20px 0px;
}

.product_left .product_zoom .product_zoom_button_group li:first-child {
  margin-top: 0px;
}

.product_left .product_zoom .product_zoom_button_group li:last-child {
  margin-bottom: 0px;
}

.product_left .product_zoom .product_zoom_button_group li a {
  position: relative;
  display: block;
  cursor: pointer;
  width: 110px;
  max-width: 100%;
  height: 110px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}

.product_left .product_zoom .product_zoom_button_group li a img {
  width: 100%;
}

.product_left .product_zoom .product_zoom_button_group li a.selected:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--primary-color), 0.5);
}

.product_left .product_zoom .product_zoom_container {
  flex: 1;
  max-height: 560px;
  overflow: hidden;
}

.product_left .product_zoom .product_zoom_container .product_zoom_info {
  position: relative;
  display: none;
}

.product_left .product_zoom .product_zoom_container .product_zoom_info img {
  width: 100%;
}

.product_right .product_info .product_title h2 {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  color: var(--heading-color);
}

.product_right .product_info .product_rating {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
}

.product_right .product_info .product_rating i {
  color: var(--second-color);
}

.product_right .product_info .product_rating .product_rating_details {
  display: inline-block;
  margin-left: 10px;
  color: #6e7a7a;
}

.product_right .product_description {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #656a7c;
  margin-bottom: 20px;
}

.product_right .product_price {
  font-family: "Nunito Sans", sans-serif;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  color: #333;
  margin-top: 15px;
  margin-bottom: 25px;
}

.product_right .product_view_bottom {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.product_right .product_view_bottom .product_quantity {
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 20px;
}

.product_right .product_view_bottom .product_quantity .pproduct_quantity_label {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--primary-color);
}

.product_right .product_view_bottom .product_quantity .product_quantity_inner {
  display: flex;
  align-items: center;
  border: 1px solid #ececec;
  margin-left: 20px;
  padding: 0px 20px;
}

.product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  .product_quantity_subtract {
  color: var(--primary-color);
  cursor: pointer;
}

.product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  .product_quantity_add {
  color: var(--primary-color);
  cursor: pointer;
}

.product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  input {
  width: 80px;
  height: 42px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  color: var(--primary-color);
  border: 0px solid #000;
  font-weight: 700;
}

.product_right .product_view_bottom .wish_button {
  margin-right: 15px;
}

.product_right .product_view_bottom .wish_button a,
.product_right .product_view_bottom .compare_button a {
  width: 44px;
  height: 44px;
  line-height: 44px;
  display: block;
  background: #f2f7f7;
  text-align: center;
  color: var(--primary-color);
}

.product_right .cart_button {
  margin-top: 20px;
}

.product_right .cart_button .button {
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  padding: 10px 25px;
  z-index: 1;
}

.product_right .cart_button .button:hover {
  color: var(--white-color);
}

.product_right .cart_button .button:before {
  background: var(--second-color);
}

.product_right .cart_button .button:after {
  background: var(--primary-color);
}

.product_right .product_view_bottom_credential {
  margin-top: 20px;
}

.product_right .product_view_bottom_credential ul {
  list-style: none;
}

.product_right .product_view_bottom_credential ul li {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: var(--body-color);
  padding: 3px 0px;
}

.product_right .product_view_bottom_credential ul li span {
  color: var(--text-heading-color);
  margin-right: 5px;
}

.product_right .product_view_bottom_credential ul li a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: var(--body-color);
}

.product_right .product_view_bottom_credential ul li.tags {
  list-style-type: none;
  display: inline-block;
  overflow: hidden;
}

.product_right .product_view_bottom_credential ul li.tags a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: var(--body-color);
  margin: 0px 5px;
}

.product_right .product_view_bottom_credential ul li.tags a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 0%;
  height: 1px;
  background: var(--second-color);
  transition: width 0.5s ease;
}

.product_right .product_view_bottom_credential ul li.tags a::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 99%;
  height: 1px;
  background: var(--body-color);
  transition: all 0.3s ease;
}

.product_right .product_view_bottom_credential ul li.tags a:hover {
  color: var(--second-color);
}

.product_right .product_view_bottom_credential ul li.tags a:hover::before {
  width: 99%;
}

.product_right .product_view_bottom_credential ul li.tags a:hover::after {
  left: 100%;
  opacity: 0;
  visibility: hidden;
}

.product_right .product_social_share {
  margin-top: 20px;
}

.product_right .product_social_share ul {
  display: flex;
  flex-flow: row wrap;
  padding: 0px;
  margin-bottom: 0px;
}

.product_right .product_social_share ul li {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #565656;
  list-style: none;
  display: inline-block;
  position: relative;
}

.product_right .product_social_share ul li:not(:last-child) {
  padding-right: 10px;
}

.product_right .product_social_share ul li a {
  position: relative;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background: #0a3f34;
  display: block;
  outline: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 100%;
}

.product_right .product_social_share ul li a:hover {
  color: var(--second-color);
}

.product_right .product_social_share ul li.facebook a {
  background: #284baf;
}

.product_right .product_social_share ul li.twitter a {
  background: #18a6f0;
}

.product_right .product_social_share ul li.instagram a {
  background: #a36ff0;
}

.product_right .product_social_share ul li.youtube a {
  background: #ff3b3b;
}

.product_right .product_social_share ul li.pinterest a {
  background: #a91010;
}

.product_right .product_social_share ul li.linkedin a {
  background: #02b8d5;
}

/*-- Product Details --*/
.product_details_tab .nav {
  gap: 60px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 18px;
}

.product_details_tab .nav li a {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: var(--heading-color);
}

.product_details_tab .nav li a.active {
  color: var(--second-color);
}

.product_details_tab .tab-content {
  padding-top: 30px;
}

.product_details_tab .tab-content p {
  line-height: 26px;
}

.product_details_tab .product_additional_info ul {
  list-style: none;
}

.product_details_tab .product_additional_info ul li {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: var(--body-color);
  padding: 3px 0px;
}

.product_details_tab .product_additional_info ul li span {
  color: var(--text-heading-color);
  margin-right: 5px;
}

.product_details_tab .product_additional_info ul li a {
  position: relative;
  line-height: 20px;
  display: inline-block;
  color: var(--body-color);
}

/*-------- Reviews ------------*/
.review_sec {
  margin-bottom: 60px;
}

.review_sec .review_title {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 15px;
  color: var(--heading-color);
}

.review_sec .review_title a {
  color: var(--heading-color);
  font-weight: 700;
}

.review_sec ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0px;
}

.review_sec ul ul {
  padding-left: 90px;
}

.review_sec ul.review_area .reviewer_div {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}

.review_sec ul.review_area .reviewer_div .reviewer {
  margin-right: 20px;
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 100%;
  overflow: hidden;
}

.review_sec ul.review_area .reviewer_div .review_block .product_rating {
  color: var(--second-color);
  line-height: 16px;
  margin-bottom: 10px;
}

.review_sec ul.review_area .reviewer_div .review_block .reviewer_name {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 0px;
  color: var(--heading-color);
  text-transform: capitalize;
}

.review_sec ul.review_area .reviewer_div .review_block .reviewer_review {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #656a7c;
  margin-top: 5px;
  margin-bottom: 12px;
}

.review_sec ul.review_area .reviewer_div .review_block .review_date {
  font-size: 12px;
  font-weight: 600;
  color: #6e7a7a;
  background-color: #ececec;
  padding: 4px 10px;
  display: inline-block;
  border-radius: 30px;
}

.review_sec ul.review_area .reviewer_div .review_block .reply a {
  font-family: "Mulish", sans-serif;
  font-size: 17px;
  font-weight: 400;
  color: var(--second-color);
}

.review_sec .reviews-pagination {
  margin-bottom: 0px;
  list-style-type: none;
  align-items: center;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0px;
}

.review_sec .reviews-pagination a,
.review_sec .reviews-pagination span {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  display: block;
  background: var(--primary-color);
  color: var(--white-color);
  border: 0px solid transparent;
  margin-right: 2px;
}

.review_sec .reviews-pagination a:hover,
.review_sec .reviews-pagination a.current,
.review_sec .reviews-pagination span:hover,
.review_sec .reviews-pagination span.current {
  background: var(--second-color);
  color: var(--white-color);
}

.review_form .review_form_heading_wrapper .review_form_title {
  position: relative;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: var(--heading-color);
  margin-bottom: 7px;
}

.review_form .review_form_heading_wrapper .review_form_title span {
  background: var(--white-color);
  padding-right: 10px;
}

.review_form .review_form_heading_wrapper .review_form_title:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: 100%;
  height: 1px;
  background-color: #ececec;
  z-index: -1;
}

.review_form .review_form_heading_wrapper .review_form_sub_title {
  font-size: 14px;
  color: #6e7a7a;
  margin-bottom: 18px;
}

.review_form .review_form_heading_wrapper .give_rating {
  font-weight: 700;
  color: #6e7a7a;
}

.review_form .review_form_heading_wrapper .reviewer_rating {
  color: var(--second-color);
}

.review_form select,
.review_form input[type="text"],
.review_form input[type="email"],
.review_form input[type="url"],
.review_form input[type="password"],
.review_form input[type="search"],
.review_form input[type="number"],
.review_form input[type="tel"],
.review_form input[type="range"],
.review_form input[type="date"],
.review_form input[type="month"],
.review_form input[type="week"],
.review_form input[type="time"],
.review_form input[type="datetime"],
.review_form input[type="datetime-local"],
.review_form input[type="color"] {
  height: 60px;
}

.review_form .button {
  font-size: 16px;
}

.related_products {
  padding-top: 100px;
  padding-bottom: 40px;
}

.related_products .section_header {
  margin-bottom: 25px;
}

.related_products .section_header .section_title {
  font-size: 24px;
  line-height: 32px;
}

.card_title {
  position: relative;
  font-weight: 700;
  color: var(--text-heading-color);
  padding-bottom: 10px;
  margin-bottom: 25px;
}

.card_title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 60px;
  height: 2px;
  background: var(--second-color);
}

@media screen and (max-width: 991px) {
  .product_right {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) {
  .product_right {
    padding-right: 0px;
  }
}

@media screen and (max-width: 500px) {
  .product_left .product_zoom {
    display: block;
  }

  .product_left .product_zoom .product_zoom_button_group {
    width: 100%;
    padding-right: 0px;
    overflow-x: scroll;
    display: flex;
    margin-bottom: 30px;
  }

  .product_left .product_zoom .product_zoom_button_group li {
    margin: 0px 5px;
  }

  .product_left .product_zoom .product_zoom_button_group li:first-child {
    margin-left: 0px;
  }

  .product_left .product_zoom .product_zoom_button_group li:last-child {
    margin-right: 0px;
  }

  .product_left .product_zoom .product_zoom_container {
    width: 100%;
  }

  .product_right .product_info .product_rating .product_rating_details {
    display: block;
    margin: 0px;
  }

  .product_right .product_info .product_rating .product_add_review {
    display: block;
    margin: 0px;
  }

  .product_right .product_view_bottom .product_quantity {
    margin-bottom: 20px;
  }
}

.cart_table {
  border-collapse: collapse;
  width: 100%;
  border: 0px;
  margin-bottom: 0px;
}

.cart_table td,
.cart_table th {
  border-right: 0px;
  border-bottom: 1px solid #ececec;
  text-align: left;
}

.cart_table .cart_header th {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--heading-color);
  padding: 15px 0px;
}

.cart_table .cart_header th.cart_header_image {
  width: 20%;
}

.cart_table .cart_header th.cart_header_title {
  width: 20%;
}

.cart_table .cart_header th.cart_header_price {
  width: 15%;
}

.cart_table .cart_header th.cart_header_quantity {
  width: 25%;
}

.cart_table .cart_content td {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  padding: 15px 0px;
}

.cart_table .cart_content .cart_image img {
  max-width: 65px;
}

.cart_table .cart_content .cart_title {
  color: var(--text-heading-color);
}

.cart_table .cart_content .cart_price {
  color: var(--body-color);
}

.cart_table .cart_content .cart_quantity .product_quantity_inner {
  display: inline-flex;
  align-items: center;
  border: 1px solid #f2f7f7;
  padding: 0px 20px;
  width: 146px;
}

.cart_table
  .cart_content
  .cart_quantity
  .product_quantity_inner
  .product_quantity_subtract {
  color: var(--primary-color);
  cursor: pointer;
}

.cart_table
  .cart_content
  .cart_quantity
  .product_quantity_inner
  .product_quantity_add {
  color: var(--primary-color);
  cursor: pointer;
}

.cart_table .cart_content .cart_quantity .product_quantity_inner input {
  width: 80px;
  height: 42px;
  padding: 0;
  text-align: center;
  background-color: transparent;
  color: var(--primary-color);
  border: 0px solid #000;
}

.cart_table .cart_content .cart_removal {
  text-align: right;
}

.cart_table .cart_content .cart_removal a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background: #fff4ee;
}

.cart_table .cart_content .cart_total {
  color: var(--second-color);
}

.couponcart {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0px;
}

.couponcart .set_coupon {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.couponcart .form-group {
  margin-bottom: 0px;
  margin-right: 18px;
}

.couponcart .form-group input[type="text"] {
  border: 1px solid #f2f7f7;
  text-align: left;
  width: 250px;
  height: 46px;
  padding: 10px 20px;
  border-radius: 0;
}

.couponcart ::-webkit-input-placeholder {
  color: var(--primary-color);
}

.couponcart ::-moz-placeholder {
  color: var(--primary-color);
}

.couponcart :-ms-input-placeholder {
  color: var(--primary-color);
}

.couponcart :-moz-placeholder {
  color: var(--primary-color);
}

.couponcart :placeholder {
  color: var(--primary-color);
}

.couponcart .button_group .button {
  font-family: "Nunito Sans", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 15px 30px;
  min-width: auto;
}

.grand_total {
  box-shadow: 0px 0px 20px rgba(171, 171, 171, 0.25);
  margin-top: 60px;
  padding: 60px;
}

.grand_total ul {
  margin: 0px;
  padding: 0px;
}

.grand_total ul li {
  list-style: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: var(--text-heading-color);
  padding: 10px 0px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  border-bottom: 1px solid #ededed;
}

.grand_total ul li span.text {
  min-width: 220px;
}

.grand_total ul li span.value {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: var(--body-color);
}

.grand_total ul li.totalvalue {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: var(--text-heading-color);
}

.grand_total ul li.totalvalue span.value {
  font-family: "Mulish", sans-serif;
  color: var(--text-heading-color);
}

.grand_total .button_group {
  margin-top: 40px;
}

.grand_total .button_group .button {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 12px 60px;
}

@media screen and (max-width: 767px) {
  .cart_table {
    width: 100%;
    display: block;
    overflow-x: scroll;
  }

  .cart_table .cart_header th:not(:last-child),
  .cart_table .cart_content td:not(:last-child) {
    padding-right: 30px;
  }

  .cart_table::-webkit-scrollbar {
    height: 5px;
  }

  .cart_table::-webkit-scrollbar-track {
    background: var(--primary-color);
    border-radius: 5px;
  }

  .cart_table::-webkit-scrollbar-thumb {
    background: var(--second-color);
    border-radius: 5px;
  }

  .grand_total {
    padding: 30px;
  }

  .grand_total .button_group .button {
    min-width: auto;
    padding: 12px 20px;
  }

  .couponcart {
    display: block;
  }

  .couponcart .form-group {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .couponcart .form-group input[type="text"] {
    width: 100%;
  }

  .couponcart .button_group {
    width: 100%;
  }

  .couponcart .button_group .button {
    width: 100%;
  }

  .couponcart .cartupdate {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #f2f7f7;
  }
}

/* Forms (shipping_form) */
.shipping_form {
  /* Coupon Code */
}

.shipping_form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow,
.shipping_form .select2-container .select2-selection--single,
.shipping_form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 50px;
  line-height: 46px;
  right: 5px;
  border: none;
}

.shipping_form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  position: relative;
  top: auto;
  left: auto;
  width: 0;
  height: 5px;
  display: inline-block;
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 0px 0px 0 0px;
  margin-left: 0;
  margin-top: 0;
}

.shipping_form
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b::before {
  content: "\f123";
  font-family: "Ionicons";
  font-size: 10px;
}

.shipping_form
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered,
.shipping_form
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0px;
  line-height: 52px;
}

.shipping_form .select2-container--default .select2-selection--multiple,
.shipping_form .select2-container--default .select2-selection--single {
  display: block;
  width: 100%;
  height: 50px;
  padding: 0px 15px;
  font-size: 14px;
  line-height: 1.5;
  background-color: var(--white-color);
  background-clip: padding-box;
  border: 1px solid #e4e4e4;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-backface-visibility: hidden;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  outline-offset: 0;
}

.shipping_form .select2-container--default .select2-selection--multiple:focus,
.shipping_form .select2-container--default .select2-selection--single:focus,
.shipping_form
  .select2-container--default.select2-container--focus
  .select2-selection--multiple,
.shipping_form
  .select2-container--default.select2-container--focus
  .select2-selection--single {
  border-color: #00326f;
  outline: none;
  outline-offset: 0px;
}

.shipping_form label {
  display: block;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--text-heading-color);
  margin-top: 0px;
  margin-bottom: 10px;
}

.shipping_form .form-group {
  margin-top: 10px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
}

.shipping_form textarea.form-control {
  height: 90px;
}

.shipping_form .form-control:focus {
  background-color: #fff;
  border-color: var(--second-color);
  outline: 0;
  box-shadow: none;
}

.shipping_form select,
.shipping_form input[type="text"],
.shipping_form input[type="email"],
.shipping_form input[type="url"],
.shipping_form input[type="password"],
.shipping_form input[type="search"],
.shipping_form input[type="number"],
.shipping_form input[type="tel"],
.shipping_form input[type="range"],
.shipping_form input[type="date"],
.shipping_form input[type="month"],
.shipping_form input[type="week"],
.shipping_form input[type="time"],
.shipping_form input[type="datetime"],
.shipping_form input[type="datetime-local"],
.shipping_form input[type="color"] {
  border: 1px solid #e4e4e4;
}

.shipping_form .have_coupon {
  background: #f7f7f7;
  border-left: 7px solid var(--second-color);
  padding: 30px 15px;
  margin-bottom: 55px;
}

.shipping_form .have_coupon .form-group {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
}

.shipping_form .have_coupon .form-group label {
  font-size: 20px;
  color: var(--text-heading-color);
  line-height: 26px;
  margin-right: 12px;
  margin-bottom: 0px;
}

.shipping_form .have_coupon .form-group input[type="text"] {
  width: 250px;
  height: 45px;
}

.shipping_form .have_coupon ::-webkit-input-placeholder {
  font-weight: 400;
  color: var(--second-color);
}

.shipping_form .have_coupon ::-moz-placeholder {
  font-weight: 400;
  color: var(--second-color);
}

.shipping_form .have_coupon :-ms-input-placeholder {
  font-weight: 400;
  color: var(--second-color);
}

.shipping_form .have_coupon :-moz-placeholder {
  font-weight: 400;
  color: var(--second-color);
}

.shipping_form .have_coupon :placeholder {
  font-weight: 400;
  color: var(--second-color);
}

.place_order {
  /* Customize Radio Button */
}

.place_order ul {
  margin: 0px;
  padding: 0px;
}

.place_order ul li {
  list-style: none;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 26px;
  color: #656a7c;
  padding: 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ededed;
}

.place_order ul li:first-child {
  padding-top: 0px;
}

.place_order ul li .form-group {
  width: auto;
  margin: 0px;
}

.place_order ul li span.value {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: var(--body-color);
}

.place_order ul li.totalvalue {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  color: var(--text-heading-color);
}

.place_order ul li.totalvalue span.text {
  margin-right: 10px;
}

.place_order ul li.totalvalue span.value {
  font-family: "Mulish", sans-serif;
  color: var(--text-heading-color);
}

.place_order label.radio_circle {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 0px;
  color: var(--body-color);
  user-select: none;
}

.place_order label.radio_circle:not(:last-child) {
  margin-right: 15px;
}

.place_order label.radio_circle input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.place_order label.radio_circle .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: var(--white-color);
  border-radius: 100%;
  border: 1px solid #788a9c;
}

.place_order label.radio_circle .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 3px;
  left: 3px;
  width: 7px;
  height: 7px;
  border-radius: 1000%;
  background: var(--second-color);
}

.place_order label.radio_circle:hover input ~ .checkmark {
  background-color: var(--white-color);
  border: 1px solid var(--second-color);
}

.place_order label.radio_circle input:checked ~ .checkmark {
  background-color: var(--white-color);
  border: 1px solid var(--second-color);
}

.place_order label.radio_circle input:checked ~ .checkmark:after {
  display: block;
}

.place_order .paymentmethod {
  margin-top: 40px;
}

.place_order .paymentmethod label.radio_circle {
  display: block;
  font-size: 16px;
  color: var(--text-heading-color);
}

.place_order .paymentmethod label.radio_circle:not(:last-child) {
  border-bottom: 1px solid #ededed;
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.place_order .paymentmethod span.text {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #656a7c;
  margin-top: 10px;
}

.place_order .button_group {
  margin-top: 25px;
}

.place_order .button_group .button {
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  padding: 12px 60px;
}

.dark-theme {
  --primary-color: #00326f;
  --second-color: #ffa903;
  --body-color: #aeb6c2;
  --text-heading-color: #00234b;
  --heading-color: #173823;
  --testimonial-below-color: #979a9f;
  --testimonial-paragraph-color: #656a7c;
  --white-color: #ffffff;
  --black-color: #000000;
  --dark-color: #222222;
  --dark-bg-color: #0a3f34;
  --deep-blue-color: #293262;
  background: var(--dark-bg-color);
}

.dark-theme textarea.form-control,
.dark-theme select,
.dark-theme input[type="text"],
.dark-theme input[type="email"],
.dark-theme input[type="url"],
.dark-theme input[type="password"],
.dark-theme input[type="search"],
.dark-theme input[type="number"],
.dark-theme input[type="tel"],
.dark-theme input[type="range"],
.dark-theme input[type="date"],
.dark-theme input[type="month"],
.dark-theme input[type="week"],
.dark-theme input[type="time"],
.dark-theme input[type="datetime"],
.dark-theme input[type="datetime-local"],
.dark-theme input[type="color"] {
  background-color: var(--heading-color);
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.dark-theme ::-webkit-input-placeholder {
  color: var(--body-color);
}

.dark-theme ::-moz-placeholder {
  color: var(--body-color);
}

.dark-theme :-ms-input-placeholder {
  color: var(--body-color);
}

.dark-theme :-moz-placeholder {
  color: var(--body-color);
}

.dark-theme :placeholder {
  color: var(--body-color);
}

.dark-theme .header {
  background: rgba(255, 255, 255, 0.1);
}

.dark-theme .header.dark_mode,
.dark-theme .header.header_color {
  background: #1a406d;
}

.dark-theme .header .top_bar {
  background-color: var(--heading-color);
  border-color: transparent;
}

.dark-theme .header .header_social .top_social li a {
  color: var(--white-color);
}

.dark-theme .header .schedule div {
  color: var(--white-color);
}

.dark-theme .header .schedule div strong {
  color: var(--white-color);
}

.dark-theme .header .header_info .free_contact a {
  background-color: var(--dark-bg-color);
}

.dark-theme .header .header_info .free_contact a:hover {
  background-color: var(--second-color);
  color: var(--primary-color);
}

.dark-theme .header .phone {
  color: var(--white-color);
}

.dark-theme .header .phone .phn_number {
  color: var(--white-color);
}

.dark-theme .header .header_search .form-control-submit {
  color: var(--white-color);
}

.dark-theme .header .mainnav ul.main_menu li a {
  color: var(--white-color);
}

.dark-theme .header .mainnav ul.main_menu li a::before {
  color: var(--white-color);
}

.dark-theme .header .mainnav ul.main_menu li.active > a {
  color: var(--second-color);
}

.dark-theme .header .mainnav ul.main_menu li.active > a::before {
  color: var(--second-color);
}

.dark-theme .header .mainnav ul.main_menu li.active > a::after {
  display: none;
}

.dark-theme .header .logo a.light_mode_logo {
  opacity: 0;
  visibility: hidden;
}

.dark-theme .header .logo a.dark_mode_logo {
  opacity: 1;
  visibility: visible;
}

.dark-theme .theme_slider_1 .slider {
  background-image: url("../../../public/images/slider/bg51.webp");
}

.dark-theme .theme_slider_1 .slider::before {
  display: none;
}

.dark-theme .theme_slider_2 .swiper-slide:nth-child(1) .slider {
  background-image: url("../../../public/images/slider/bg3.jpg");
}

.dark-theme .theme_slider_2 .swiper-slide:nth-child(2) .slider {
  background-image: url("../../../public/images/slider/bg1.jpg");
}

.dark-theme .bg_1 {
  background: #2b4c75;
}

.dark-theme .bg_3 {
  background: #0f3665;
}

.dark-theme .section_header .section_title {
  color: var(--white-color);
}

.dark-theme .experience .section_header .section_title {
  color: var(--white-color);
}

.dark-theme .experience .section_header .section_desc {
  color: var(--body-color);
}

.dark-theme .experience .section_header .short_detail {
  background-color: var(--heading-color);
  color: #cacfeb;
}

.dark-theme .experience .section_header .section_call .call_info h4 {
  color: var(--white-color);
}

.dark-theme .experience_content .about_below .about_below_content i {
  background: var(--heading-color);
  color: var(--white-color);
}

.dark-theme
  .experience_content
  .about_below
  .about_below_content
  .about_below_content_text
  h5 {
  color: var(--white-color);
}

.dark-theme .experience_content.about .about_below .about_below_content img {
  box-shadow: none;
}

.dark-theme
  .experience_content.about
  .about_below
  .about_below_content
  .about_below_content_text
  p {
  color: var(--body-color);
}

.dark-theme .patner .patner_image {
  border-color: rgba(255, 255, 255, 0.5);
}

.dark-theme .top_feature .content_inner h1 {
  color: var(--white-color);
}

.dark-theme .service_inner_block::after {
  background: #002a5c;
}

.dark-theme .service_inner_block .service_content p {
  color: var(--body-color);
}

.dark-theme .service_inner_block .service_content h4,
.dark-theme .service_inner_block .service_content a {
  color: var(--white-color);
}

.dark-theme .service_inner_block:hover::after {
  background: rgba(0, 42, 92, 0.7);
}

.dark-theme
  .owl_service.owl-carousel
  .owl-item.active.center
  .service_inner_block::after {
  background: rgba(0, 42, 92, 0.7);
}

.dark-theme .owl_service.owl-carousel .owl-nav button.owl-prev {
  background-color: var(--deep-blue-color);
  color: var(--second-color);
}

.dark-theme .owl_service.owl-carousel .owl-nav button.owl-prev:hover {
  background-color: var(--white-color);
  color: var(--deep-blue-color);
}

.dark-theme .owl_service.owl-carousel .owl-nav button.owl-next {
  background-color: var(--second-color);
  color: var(--white-color);
}

.dark-theme .owl_service.owl-carousel .owl-nav button.owl-next:hover {
  background-color: var(--white-color);
  color: var(--deep-blue-color);
}

.dark-theme .testimonial .section_header .section_title {
  color: var(--heading-color);
}

.dark-theme .blog .section_header_width_no_margin .section_title {
  color: var(--white-color);
}

.dark-theme .blog .blog_grid .section_header .section_desc {
  color: var(--body-color);
}

.dark-theme .blog .blog_grid .read_all a {
  background-color: var(--second-color);
}

.dark-theme .blog .blog_grid .read_all a:hover {
  background-color: var(--heading-color);
}

.dark-theme .blog_post {
  box-shadow: none;
}

.dark-theme .blog_post .post_content {
  background-color: var(--heading-color);
  border-color: rgba(151, 154, 159, 0.3);
}

.dark-theme .blog_post .post_content .post_header .post_subtitle h6 {
  color: var(--body-color);
}

.dark-theme .blog_post .post_content .post_title a {
  color: var(--white-color);
}

.dark-theme .blog_post .post_content .post_title a:hover {
  color: var(--second-color);
}

.dark-theme .blog_post .post_content .post_intro p {
  color: var(--body-color);
}

.dark-theme .blog_post .post_content .read_more a {
  color: var(--body-color);
}

.dark-theme .blog_post .post_content .read_more a:hover {
  color: var(--second-color);
}

.dark-theme .blog_post.blog_post2 .post_content {
  border-color: transparent;
}

.dark-theme .owl_team.owl-carousel .owl-nav button.owl-prev,
.dark-theme .owl_blog.owl-carousel .owl-nav button.owl-prev {
  background-color: var(--deep-blue-color);
  color: var(--second-color);
}

.dark-theme .owl_team.owl-carousel .owl-nav button.owl-prev:hover,
.dark-theme .owl_blog.owl-carousel .owl-nav button.owl-prev:hover {
  background-color: var(--white-color);
  color: var(--deep-blue-color);
}

.dark-theme .owl_team.owl-carousel .owl-nav button.owl-next,
.dark-theme .owl_blog.owl-carousel .owl-nav button.owl-next {
  background-color: var(--second-color);
  color: var(--white-color);
}

.dark-theme .owl_team.owl-carousel .owl-nav button.owl-next:hover,
.dark-theme .owl_blog.owl-carousel .owl-nav button.owl-next:hover {
  background-color: var(--white-color);
  color: var(--deep-blue-color);
}

.dark-theme .owl-theme .owl-dots .owl-dot span {
  background-color: #f2f7f7;
}

.dark-theme .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--second-color);
}

.dark-theme .testimonial .owl-theme .owl-dots .owl-dot span,
.dark-theme .testimonial2 .owl-theme .owl-dots .owl-dot span {
  background-color: var(--primary-color);
}

.dark-theme .testimonial .owl-theme .owl-dots .owl-dot.active span,
.dark-theme .testimonial2 .owl-theme .owl-dots .owl-dot.active span {
  background-color: var(--second-color);
}

.dark-theme .funfacts2_bg {
  background-image: url(../../../public/images/funfact/bg5.png);
  background-color: var(--heading-color);
  box-shadow: 0px 10px 25px rgba(10, 33, 72, 0.3);
}

.dark-theme .funbox2 .fun_content h3,
.dark-theme .funbox2 .fun_content p {
  color: var(--white-color);
}

.dark-theme .team_construction .team_construction_inner .team-box__info {
  background: rgba(13, 36, 74, 0.04);
  border-color: rgba(13, 36, 74, 0.08);
}

.dark-theme .team_construction .team_construction_inner .team-box__info a {
  color: var(--white-color);
}

.dark-theme .team_construction .team_construction_inner .team-box__info p {
  color: var(--testimonial-below-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content {
  background-color: var(--heading-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content h3 a {
  color: var(--white-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content h3 a:hover {
  color: var(--second-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content p {
  color: var(--body-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content .read_more a {
  color: var(--white-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content .read_more a span {
  background: var(--heading-color);
}

.dark-theme .iconbox .iconbox_wrapper .iconbox_content .read_more a:hover {
  color: var(--second-color);
}

.dark-theme .project_details .post_header .post_title {
  color: var(--white-color);
}

.dark-theme .project_details .fulltext {
  color: var(--body-color);
}

.dark-theme .project_details .fulltext .widget_title {
  color: var(--white-color);
}

.dark-theme .project_details ul.point_order li {
  color: var(--white-color);
}

.dark-theme .accordion .item .accordion_tab {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .accordion .item .accordion_tab .accordion_title {
  color: var(--white-color);
}

.dark-theme .accordion .item .accordion_tab .accordion_tab_icon {
  color: var(--body-color);
}

.dark-theme .accordion .item .accordion_info {
  color: var(--body-color);
}

.dark-theme .accordion .item.active .accordion_tab .accordion_tab_icon {
  color: var(--white-color);
}

.dark-theme .project_details_inner .testibox1 .testibox_inner {
  background-color: var(--heading-color);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .project_details_inner .testibox1 ul li.text {
  color: var(--white-color);
}

.dark-theme .project_details_inner .testibox1 p {
  color: var(--body-color);
}

.dark-theme .project_details_inner .testibox1 h3 {
  color: var(--white-color);
}

.dark-theme .project_details_inner .testibox1 h6 {
  color: var(--body-color);
}

.dark-theme .project_details_inner .testibox1::before {
  opacity: 0;
}

.dark-theme .project_details_inner .testibox1::after {
  opacity: 1;
}

.dark-theme .project_details .fulltext .share_tag {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark-theme
  .project_details
  .post_share
  ul.share_list.new_share
  li:first-child {
  color: var(--white-color) !important;
}

.dark-theme .inner_posts {
  background-color: var(--heading-color);
}

.dark-theme .inner_posts .inner-post .post_block > a {
  color: var(--body-color);
}

.dark-theme .inner_posts .inner-post .post_block h4 a {
  color: var(--white-color);
}

.dark-theme
  .project_info
  .project_info_bg
  .project_info_details_bg
  .project_info_details {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .sidebar {
  background-color: transparent;
}

.dark-theme .widget_title {
  color: var(--white-color);
}

.dark-theme .widget_recent_posts ul li h5 a {
  color: var(--white-color);
}

.dark-theme .widget_categories ul li {
  color: var(--body-color);
}

.dark-theme .sidebar_tags ul li a:hover {
  color: var(--white-color);
}

.dark-theme .sidebar_tags ul li a::before {
  background: var(--white-color);
}

.dark-theme .sidenav ul li a {
  background-color: var(--heading-color);
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark-theme .sidenav ul li a img:first-child {
  opacity: 0;
  visibility: hidden;
}

.dark-theme .sidenav ul li a img:last-child {
  opacity: 1;
  visibility: visible;
}

.dark-theme .sidenav ul li a:hover {
  background-color: var(--second-color);
}

.dark-theme .sidenav ul li a:hover img:first-child {
  opacity: 1;
  visibility: visible;
}

.dark-theme .sidenav ul li a:hover img:last-child {
  opacity: 0;
  visibility: hidden;
}

.dark-theme .sidenav ul li.active a {
  background-color: var(--second-color);
}

.dark-theme .blog_details .post_header .post_title {
  color: var(--white-color);
}

.dark-theme .blog_details .fulltext {
  color: var(--body-color);
}

.dark-theme .blog_details .fulltext .highlight {
  color: var(--second-color);
}

.dark-theme .blog_details .fulltext .widget_title {
  color: var(--white-color);
}

.dark-theme .blog_details ul.point_order li {
  color: var(--white-color);
}

.dark-theme .blog_details .post_share ul.share_list li:first-child,
.dark-theme .blog_details .author_div .author_name,
.dark-theme .blog_details .author_div .social_media ul.social_list li a {
  color: var(--white-color);
}

.dark-theme .comment_sec .widget_title {
  color: var(--white-color);
}

.dark-theme
  .comment_sec
  ul.comment_area
  .commenter_div
  .comment_block
  .commenter_name {
  color: var(--white-color);
}

.dark-theme
  .comment_sec
  ul.comment_area
  .commenter_div
  .comment_block
  .commenter_review {
  color: var(--body-color);
}

.dark-theme .comment_sec .comments-pagination a,
.dark-theme .comment_sec .comments-pagination span:not(.current) {
  background-color: var(--heading-color);
  color: var(--white-color);
}

.dark-theme .comment_sec .comments-pagination a:hover,
.dark-theme .comment_sec .comments-pagination span:not(.current):hover {
  background-color: var(--second-color);
}

.dark-theme .makeacomment .widget_title {
  color: var(--white-color);
}

.dark-theme .makeacomment .contact_form .comment-notes {
  color: var(--body-color);
}

.dark-theme .keepintouch_3 .communication {
  background-color: var(--dark-bg-color);
  box-shadow: 0px 0px 20px rgba(255, 255, 255, 0.2);
}

.dark-theme .keepintouch_3 .communication .communication_icon {
  background: var(--white-color);
}

.dark-theme .keepintouch_3 .communication .info_body h5 {
  color: var(--white-color);
}

.dark-theme .pagination li a {
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .pagination li a.page-number:not(.current) {
  background-color: var(--heading-color);
}

.dark-theme .pagination li a.page-number:not(.current):hover {
  background-color: var(--second-color);
}

.dark-theme
  .product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: var(--white-color);
}

.dark-theme
  .product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  color: var(--body-color);
}

.dark-theme
  .product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  color: var(--testimonial-paragraph-color);
}

.dark-theme
  .product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a {
  color: var(--body-color);
}

.dark-theme
  .product_view_grid
  .product_item
  .product_thumb
  .product_item_inner
  .product_category
  a:hover {
  color: var(--white-color);
}

.dark-theme .product_view_list {
  background-color: transparent;
}

.dark-theme .product_view_list .product_item {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme
  .product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .label_text
  .product_item_name
  a {
  color: var(--white-color);
}

.dark-theme
  .product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price {
  color: var(--body-color);
}

.dark-theme
  .product_view_list
  .product_item
  .product_thumb
  .product_item_inner
  .product_item_price
  del {
  color: var(--testimonial-paragraph-color);
}

.dark-theme .product_right .product_info .product_title h2 {
  color: var(--white-color);
}

.dark-theme
  .product_right
  .product_info
  .product_rating
  .product_rating_details {
  color: var(--body-color);
}

.dark-theme .product_right .product_price {
  color: var(--white-color);
}

.dark-theme .product_right .product_description {
  color: var(--body-color);
}

.dark-theme
  .product_right
  .product_view_bottom
  .product_quantity
  .pproduct_quantity_label {
  color: var(--white-color);
}

.dark-theme
  .product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme
  .product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  .product_quantity_subtract {
  color: var(--white-color);
}

.dark-theme
  .product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  .product_quantity_add {
  color: var(--white-color);
}

.dark-theme
  .product_right
  .product_view_bottom
  .product_quantity
  .product_quantity_inner
  input {
  color: var(--white-color);
}

.dark-theme .product_right .product_view_bottom_credential ul li span {
  color: var(--white-color);
}

.dark-theme .product_right .product_social_share ul li {
  color: var(--white-color);
}

.dark-theme .product_details_tab .nav {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .product_details_tab .nav li a {
  color: var(--white-color);
}

.dark-theme .product_details_tab .nav li a.active {
  color: var(--second-color);
}

.dark-theme .product_details_tab .product_additional_info ul li span {
  color: var(--white-color);
}

.dark-theme .product_details_tab .review_sec .review_title {
  color: var(--body-color);
}

.dark-theme .product_details_tab .review_sec .review_title a {
  color: var(--white-color);
}

.dark-theme
  .product_details_tab
  .review_sec
  ul.review_area
  .reviewer_div
  .review_block
  .reviewer_name {
  color: var(--white-color);
}

.dark-theme
  .product_details_tab
  .review_sec
  ul.review_area
  .reviewer_div
  .review_block
  .reviewer_review {
  color: var(--body-color);
}

.dark-theme
  .review_form
  .review_form_heading_wrapper
  .review_form_title::before {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .review_form .review_form_heading_wrapper .review_form_title span {
  background-color: #0d244a;
  color: var(--white-color);
}

.dark-theme .review_form .review_form_heading_wrapper .review_form_sub_title {
  color: var(--body-color);
}

.dark-theme .review_form .review_form_heading_wrapper .give_rating {
  color: var(--body-color);
}

.dark-theme .cart_table td,
.dark-theme .cart_table th {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .cart_table .cart_header th {
  color: var(--white-color);
}

.dark-theme .cart_table .cart_content .cart_title {
  color: var(--white-color);
}

.dark-theme .cart_table .cart_content .cart_price {
  color: var(--body-color);
}

.dark-theme .cart_table .cart_content .cart_quantity .product_quantity_inner {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme
  .cart_table
  .cart_content
  .cart_quantity
  .product_quantity_inner
  .product_quantity_subtract {
  color: var(--white-color);
}

.dark-theme
  .cart_table
  .cart_content
  .cart_quantity
  .product_quantity_inner
  .product_quantity_add {
  color: var(--white-color);
}

.dark-theme
  .cart_table
  .cart_content
  .cart_quantity
  .product_quantity_inner
  input {
  color: var(--white-color);
}

.dark-theme .cart_table .cart_content .cart_removal {
  text-align: right;
}

.dark-theme .cart_table .cart_content .cart_removal a {
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 38px;
  text-align: center;
  border-radius: 100%;
  background: #fff4ee;
}

.dark-theme .cart_table .cart_content .cart_total {
  color: var(--second-color);
}

.dark-theme .couponcart .form-group input[type="text"] {
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .couponcart ::-webkit-input-placeholder {
  color: var(--body-color);
}

.dark-theme .couponcart ::-moz-placeholder {
  color: var(--body-color);
}

.dark-theme .couponcart :-ms-input-placeholder {
  color: var(--body-color);
}

.dark-theme .couponcart :-moz-placeholder {
  color: var(--body-color);
}

.dark-theme .couponcart :placeholder {
  color: var(--body-color);
}

.dark-theme .card_title {
  color: var(--white-color);
}

.dark-theme .grand_total {
  box-shadow: 0px 0px 20px rgba(0, 50, 111, 0.3);
}

.dark-theme .grand_total ul li {
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.dark-theme .grand_total ul li.totalvalue span.value {
  color: var(--white-color);
}

.dark-theme
  .shipping_form
  .select2-container--default
  .select2-selection--multiple,
.dark-theme
  .shipping_form
  .select2-container--default
  .select2-selection--single {
  background-color: var(--heading-color);
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--white-color);
}

.dark-theme .shipping_form .form-control:focus {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.5);
}

.dark-theme .shipping_form label {
  color: var(--body-color);
}

.dark-theme
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: var(--body-color);
}

.dark-theme .place_order ul li {
  color: var(--body-color);
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .place_order ul li.totalvalue span.value {
  color: var(--white-color);
}

.dark-theme .place_order .paymentmethod label.radio_circle {
  color: var(--white-color);
  border-color: rgba(255, 255, 255, 0.2);
}

.dark-theme .place_order .paymentmethod span.text {
  color: var(--body-color);
}

.dark-theme .shop_sidebar .sidebar_price_filter .range-value {
  color: var(--white-color);
}

.dark-theme
  .shop_sidebar
  .sidebar_price_filter
  .range-value
  input[type="text"] {
  color: var(--white-color);
}

.dark-theme .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-range,
.dark-theme .shop_sidebar .sidebar_price_filter .range-bar .ui-slider-handle {
  background-color: var(--second-color);
}

.dark-theme .shop_sidebar .form-check label {
  color: var(--body-color);
}

.dark-theme .shop_sidebar .form-check-input:checked {
  background-color: var(--second-color);
  border-color: var(--second-color);
}

.dark-theme
  .sidebar_popular_products
  ul
  li
  .popular_product_content
  .product_title
  a {
  color: var(--white-color);
}

.dark-theme
  .sidebar_popular_products
  ul
  li
  .popular_product_content
  .product_price {
  color: var(--body-color);
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.container_new {
  margin-left: calc((100% - (1223px - 1.5rem)) / 2);
}

.btn:focus {
  box-shadow: none;
}

.section_header {
  margin-bottom: 45px;
}

.section_header .shadow_icon {
  margin-bottom: -30px;
}

.section_header .section_sub_title {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 26px;
  color: var(--second-color);
}

.section_header .section_title {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  color: var(--heading-color);
  letter-spacing: 0px;
  margin-top: 6px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.section_header .section_desc {
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  color: #656a7c;
  max-width: 530px;
  margin: 0 auto;
}

.section_header_white .section_title {
  color: var(--white-color);
}

.faq .section_header {
  padding-top: 110px;
}

.slide_navi {
  display: none;
}

/*
 * Shortcode: Banner
 * -----------------------------------------------
*/
.banner_bg_color {
  background: var(--second-color);
}

.banner .banner_content {
  background: var(--second-color);
  padding: 60px 0px;
}

@media (max-width: 394px) {
  .banner .banner_content {
    text-align: center;
  }
  .banner .banner_content .banner_text img {
    margin: 20px auto 0 !important;
  }
}

.banner .banner_content .row {
  align-items: center;
}

.banner .banner_content .banner_text {
  padding-left: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid var(--white-color);
}

.banner .banner_content .banner_text h1 {
  font-weight: 700;
  line-height: 44px;
  color: var(--white-color);
}

.banner .banner_content .banner_text img {
  margin-right: 20px;
}

.banner .banner_content .banner_phone span {
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  color: var(--heading-color);
}

.banner .banner_content .banner_phone h4 {
  color: var(--white-color);
  font-size: 30px;
  line-height: 45px;
  letter-spacing: -0.42px;
}

.banner.type_2 .banner_content {
  background: #4c1d1c;
  border-radius: 10px;
}

.banner.type_2 .banner_content .banner_text h1 {
  color: var(--second-color);
}

.banner.type_2 .banner_content .banner_phone span {
  color: var(--second-color);
}

.banner.type_3 {
  margin-bottom: -113px;
  position: relative;
  z-index: 9;
}

.banner.type_3 .banner_content {
  background: var(--second-color);
  border-radius: 8px;
}

.patner {
  background: var(--second-color);
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.patner .patner_image {
  position: relative;
  border-right: 1px solid var(--white-color);
}

.patner .patner_image:last-child {
  border-right-width: 0px;
}

.patner_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patner_2_margin {
  margin-top: 400px;
}

.bg_1 {
  background: #002149;
}

.bg_2 {
  background: var(--second-color);
}

.bg_3 {
  background: #00326f;
}

/*---------- Breadcrumb Section ------------*/
.page_header {
  position: relative;
  overflow: hidden;
}

.page_header::before {
  position: absolute;
  content: url("../../../public/images/about/s1.png");
  z-index: 99;
  left: 0;
  top: 48px;
  animation: headerobject-move1 4s;
}

.page_header::after {
  position: absolute;
  content: "";
  background: url("../../../public/images/about/s2.png");
  z-index: 99;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 70%;
  background-repeat: no-repeat;
  background-position: bottom right;
  animation: headerobject-move2 4s;
}

@keyframes headerobject-move1 {
  0% {
    top: 48px;
  }
  50% {
    top: -50px;
  }
  100% {
    top: 48px;
  }
}

@keyframes headerobject-move2 {
  0% {
    bottom: 0px;
  }
  50% {
    bottom: -50px;
  }
  100% {
    bottom: 0px;
  }
}

.page_header .page_header_content {
  position: relative;
  background-image: url("../../../public/images/slider/bg5.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  padding: 160px 0 135px 0;
  text-align: center;
}

@media (max-width: 640px) {
  .page_header .page_header_content {
    padding: 55px 0 40px 0;
  }
}

.page_header .page_header_content > div {
  position: relative;
}

.page_header .page_header_content .heading {
  position: relative;
  font-size: 52px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0px;
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 0px 120px;
  color: var(--second-color);
}

.page_header .page_header_content::before {
  position: absolute;
  content: "";
  /*background: #0A3F34;*/
  width: 100%;
  height: 100%;
  /*opacity: .85;*/
  top: 0;
  left: 0;
}

.page_header .breadcrumb {
  padding: 10px 0px 0px 0px;
  margin-bottom: 0;
  list-style: none;
  display: block;
  background: transparent;
}

.page_header .breadcrumb li {
  position: relative;
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;
  line-height: 32px;
}

.page_header .breadcrumb li:not(:last-child) {
  margin-right: 6px;
}

.page_header .breadcrumb li:before {
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  background: var(--white-color);
  margin-right: 7px;
}

.page_header .breadcrumb li:first-child:before {
  display: none;
}

.page_header .breadcrumb li a {
  color: var(--white-color);
}

.page_header .breadcrumb li a:hover {
  color: var(--white-color);
}

.page_header .breadcrumb li.active {
  color: var(--white-color);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #dcdcdc;
  height: 35px;
}

.select2-dropdown {
  border-color: #dcdcdc;
}

.select2-results__option {
  padding: 2px 9px;
  font-size: 14px;
}

.select2-container--default
  .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--second-color);
  color: var(--white-color);
}

/* Owl Carousel Navigation Customization*/
.owl-carousel.owl-theme .owl-nav {
  position: absolute;
  top: 40%;
  transform: translateY(-60%);
  width: 100%;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  background: #f2f7f7;
  opacity: 1;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  transition: all 0.7s ease;
  color: #173823;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  background: var(--second-color);
  color: var(--white-color);
}

.owl-carousel.owl-theme .owl-nav .owl-prev {
  position: absolute;
  left: -70px;
}

.owl-carousel.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: -70px;
}

.owl-carousel.owl-theme .owl-nav .disabled {
  opacity: 1;
}

/*-- Animated / Floating Object --*/
.floating_object {
  display: inline-block;
  position: absolute;
  z-index: 1;
}

.floating_object_1 {
  top: 30%;
  right: 33%;
  animation: floating_animate1 6s infinite;
}

.floating_object_2 {
  top: 47%;
  right: 6%;
  animation: floating_animate1 7s infinite;
}

.floating_object_3 {
  top: 28%;
  right: -7%;
  animation: floating_animate1 6s infinite;
}

.floating_object_4 {
  top: 42%;
  right: 1%;
  animation: floating_animate1 9s infinite;
}

.floating_object_5 {
  top: 7%;
  right: -5%;
  animation: floating_animate3 10s linear infinite;
}

.floating_object_6 {
  top: 30%;
  right: -15%;
  animation: floating_animate2 6s infinite;
}

.floating_object_7 {
  right: -8%;
  bottom: -7%;
}

@keyframes floating_animate1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes floating_animate2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes floating_animate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes floating_animate4 {
  0% {
    right: -8%;
  }
  50% {
    right: -5%;
  }
  100% {
    right: -8%;
  }
}

/*-- Image overlay --*/
[data-aos="overlay-left"],
[data-aos="overlay-top"],
[data-aos="overlay-right"],
[data-aos="overlay-bottom"] {
  position: relative;
  overflow: hidden;
}

[data-aos="overlay-left"]:before,
[data-aos="overlay-top"]:before,
[data-aos="overlay-right"]:before,
[data-aos="overlay-bottom"]:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: var(--second-color);
  transition: 2s cubic-bezier(0.858, 0.01, 0.068, 0.99);
}

[data-aos="overlay-left"].aos-animate:before {
  transform: translateX(-105%);
}

[data-aos="overlay-top"].aos-animate:before {
  transform: translateY(-105%);
}

[data-aos="overlay-right"].aos-animate:before {
  transform: translateX(105%);
}

[data-aos="overlay-bottom"].aos-animate:before {
  transform: translateY(105%);
}

/*-- Preloader --*/
#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  transition: all 0.5s ease-in-out;
}

#preloader.hide {
  width: 0%;
  overflow: hidden;
}

#preloader .hide-loader {
  position: absolute;
  left: 15px;
  top: 15px;
  background-color: #2e2e2e;
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  z-index: 5;
  white-space: nowrap;
  transition: all 0.5s ease-in-out;
}

#preloader.hide .hide-loader {
  opacity: 0;
  visibility: hidden;
}

#preloader:before,
#preloader:after {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: var(--primary-color);
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
}

#preloader:after {
  background: var(--second-color);
  mix-blend-mode: difference;
  transition-delay: 0.13s;
  z-index: 3;
}

#preloader.hide:before,
#preloader.hide:after {
  transform: translateX(-105%);
}

.loader-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}

#preloader.hide .loader-wrap {
  opacity: 0;
  visibility: hidden;
  transition-delay: 0.18s;
}

.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid #ffffff;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  background-color: #ffffff;
  animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }
  25% {
    height: 0%;
  }
  50% {
    height: 100%;
  }
  75% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

#mode_switcher {
  position: fixed;
  right: 20px;
  top: calc(100vh / 4);
  cursor: pointer;
  z-index: 9999;
}

.dark-theme #mode_switcher span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffffff;
  font-size: 50px;
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotateSwitcher 5s infinite linear;
}

#mode_switcher span {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffffff;
  font-size: 50px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotateSwitcher 5s infinite linear;
}

@keyframes rotateSwitcher {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1740px) {
  .another_bg_image::before {
    width: 39%;
  }

  .another_bg_image::after {
    width: 39%;
  }
}

@media screen and (max-width: 1450px) {
  .contact_inner::before {
    width: 22%;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1450px) {
  .theme_slider_3 .slick-dots {
    left: 30px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1670px) {
  .service_inner .service_content {
    margin-bottom: 30px;
  }

  .service_inner .service_content h6 {
    letter-spacing: 3px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
  .service_inner .service_content h4 {
    margin: 20px 0 15px 0;
  }

  .service_inner .service_content h6 {
    font-size: 7px;
    line-height: 10px;
    letter-spacing: 3px;
  }

  .testimonial2 .slick-dots {
    right: 40px;
  }
}

@media screen and (max-width: 1344px) {
  .patner .patner_image::before {
    height: 188px;
  }
}

@media screen and (max-width: 1300px) {
  .owl-carousel.owl-theme .owl-nav {
    position: relative;
    transform: translateY(0%);
  }

  .owl-carousel.owl-theme .owl-nav .owl-prev {
    position: relative;
    left: auto;
  }

  .owl-carousel.owl-theme .owl-nav .owl-next {
    position: relative;
    right: auto;
  }

  .experience_content {
    padding-left: 50px;
  }

  .contact_inner::before {
    content: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1575px) {
  .teambox .teambox_inner {
    padding-left: 20px;
  }

  .teambox .teambox_inner .teambox_intro .team_flex h5 {
    font-size: 20px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  .experience_content
    .about_below
    .about_below_content
    .about_below_content_text {
    margin-left: 5px;
  }

  .experience_content
    .about_below
    .about_below_content
    .about_below_content_text
    p {
    font-size: 16px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .footer .side_footer_social .bottom_social li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 12px;
  }

  .footer .side_footer_social {
    margin-top: 20px;
  }

  .keepintouch_3 .communication {
    padding: 15px;
  }

  .keepintouch_3 .communication .info_body {
    margin-left: 15px;
  }
}

@media screen and (min-width: 767px) and (max-width: 900px) {
  .keepintouch_3 .communication {
    padding: 10px;
  }

  .keepintouch_3 .communication .info_body {
    margin-left: 15px;
  }
}

/*-- max-width: 1199px--*/
@media screen and (max-width: 1199.9px) {
  .header .top_bar_inner .header_info {
    margin-left: 0;
  }

  .header .middle_bar_inner {
    padding: 15px 0;
  }

  .header .mainnav {
    margin-left: 42px;
  }

  .header .mainnav ul.main_menu {
    display: none;
  }

  .header .mainnav ul.main_menu > li {
    margin: 0px 15px;
  }

  .header .ma5menu__toggle {
    display: block;
    margin-left: 15px;
  }

  .section_header .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .experience .section_header .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .experience_content {
    padding-left: 5px;
  }

  .experience .about .section_header .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .group_image_holder {
    position: relative;
    text-align: right;
  }

  .group_image_holder .object img:first-child {
    top: 80px;
    right: -21px;
  }

  .group_image_holder .object img:nth-child(2) {
    bottom: 61px;
    left: 45.5%;
  }

  .group_image_holder .object img:nth-child(3) {
    bottom: 62px;
    right: -11px;
    transform: translateX(47%);
  }

  .group_image_holder .object img:nth-child(4) {
    left: 0;
  }

  .group_image_holder .expe_box {
    left: -20px;
  }

  .blog .section_header {
    margin-right: 0;
  }

  .banner .banner_content {
    background: var(--second-color);
    padding: 40px 0px;
  }

  .banner .banner_content .banner_text h1 {
    font-size: 26px;
    line-height: 34px;
  }

  .banner .banner_content .banner_phone span {
    font-size: 26px;
  }

  .theme_slider_1 .slider .slide_content .heading {
    font-size: 60px;
    line-height: 80px;
  }

  .patner .patner_image::before {
    right: 3px !important;
  }

  .footer .footer_above2 .footer_contact .footer_contact_inner {
    display: block;
  }

  .footer
    .footer_above2
    .footer_contact
    .footer_contact_inner
    .contact_content {
    margin-top: 15px;
  }

  .project_iso .grid .element-item {
    width: 30%;
  }

  .project_iso .grid.grid-5 .element-item {
    width: 33.33%;
  }

  .funfacts2_bg,
  .funfacts3_bg {
    padding: 33px 0;
  }

  .funbox2::before {
    right: -45px;
  }

  .upper_sm_img .group_image_holder::before {
    width: 461px;
    height: 430px;
    top: -12px;
    left: -15px;
  }

  .experience_content .about_below {
    flex-flow: row wrap;
  }

  .experience_content .about_below .about_below_content {
    margin-bottom: 20px;
  }

  .experience_content .about_below .about_below_content:last-child {
    margin-bottom: 0;
  }

  .service_inner {
    padding: 20px 0px 0px 15px;
  }

  .service_inner .services_content_flex_cenrer {
    padding-bottom: 20px;
  }

  .service_inner .main_img {
    display: none;
  }

  .service_inner .service_content h4 a {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }

  .owl_service.owl-carousel.owl-theme .owl-nav {
    position: relative;
    top: 0;
    right: 0;
  }

  .owl_service.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: auto;
    right: auto;
  }

  .owl_service.owl-carousel.owl-theme .owl-nav .owl-next {
    left: auto;
    right: auto;
  }

  .about_bg_image {
    background-image: none;
  }

  .blog .section_header_width_no_margin .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .contact_us_1 {
    padding: 40px;
  }

  .contact_us_1 .section_header .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .container_new {
    margin-left: calc((100% - (991px - 1.5rem)) / 2);
  }

  .theme_slider_1 .slider .slide_content .heading,
  .theme_slider_2 .slider .slide_content .heading {
    font-size: 46px;
    line-height: 50px;
  }
}

/*-- max-width: 991px--*/
@media screen and (max-width: 991px) {
  .container_new {
    margin-left: 0;
    padding: 0 var(--bs-gutter-x, 0.1rem);
  }

  .header .header_social h6 {
    display: none;
  }

  .header .header_search .search_form {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .header .header_right_part #open_search {
    top: 49px;
    right: 55px;
  }

  .header .header_info .schedule {
    padding: 0 5px 0 0;
  }

  .header .header_info .schedule div {
    margin-left: 3px;
  }

  .header .header_info .free_contact a {
    padding: 10px 15px;
  }

  .footer .footer_bottom {
    padding: 30px 30px;
  }

  .theme_slider_3 .slider .slide_content .heading {
    font-size: 36px;
    line-height: 48px;
  }

  .theme_slider_3 .slick-dots {
    bottom: 60px;
    top: auto;
    left: 10px;
    display: flex;
    width: auto;
  }

  .section_header .section_title {
    font-size: 30px;
    line-height: 40px;
  }

  .experience_content {
    padding-top: 0;
    padding-left: 0px;
  }

  .experience_content .section_header .section_desc {
    max-width: 100%;
  }

  .expe_box {
    right: -10px;
  }

  .group_image_holder {
    display: none;
  }

  .banner .banner_content .banner_text {
    padding-left: 0;
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    border-right: 0;
    width: 100%;
  }

  .banner .banner_content .banner_text h1 {
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .banner .banner_content .banner_phone {
    margin-top: 20px;
    text-align: center;
  }

  .footer .footer_bottom .footer_bottom_inner {
    display: block;
    text-align: center;
  }

  .footer .footer_bottom .footer_bottom_inner .footer_nav_bottom ul {
    display: block;
  }

  .footer .footer_bottom .footer_bottom_inner .copyright p {
    margin-top: 15px 0 10px 0;
  }

  .footer .footer_bottom .footer_bottom_inner .totop {
    text-align: center;
    margin-top: 15px;
    margin-left: 0;
  }

  .footer .footer_bottom .footer_bottom_inner > div:not(:last-child) {
    margin-bottom: 10px;
  }

  .footer
    .footer_bottom
    .footer_bottom_inner
    .footer_nav_bottom
    ul
    li:first-child {
    margin-right: 0;
  }

  .side_footer_social {
    margin-top: 20px;
  }

  .side_footer_social .bottom_social li a {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }

  .read_more {
    margin-bottom: 20px;
  }

  .blog .section_header {
    text-align: center !important;
  }

  .blog .read_more {
    text-align: center;
    margin-bottom: 20px;
  }

  .blog .blog_post2 .read_more {
    text-align: left;
  }

  .funfacts .man_img {
    justify-content: center;
  }

  .theme_slider_1 .bg_image_11 {
    width: 100%;
  }

  .theme_slider_2 .slider {
    padding-top: 170px;
    padding-bottom: 100px;
  }

  .theme_slider_2 .swiper-pagination {
    bottom: 40px;
    top: auto;
    left: 20px;
    display: flex;
    width: auto;
    gap: 10px;
  }

  .patner {
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
    padding: 15px 10px;
  }

  .patner .patner_image {
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: -1px;
    margin-right: -1px;
    width: 48%;
  }

  .patner .patner_image:last-child {
    border-right-width: 1px;
  }

  .blog .section_header_width_no_margin {
    width: 100%;
  }

  .footer .footer_above2 .footer_contact {
    display: block;
    text-align: center;
  }

  .footer .footer_above2 .footer_contact .footer_contact_inner {
    margin-bottom: 15px;
  }

  .footer .footer_above2 .footer_contact .footer_contact_inner::before {
    content: none;
  }

  .footer .footer_above2 .footer_contact .footer_contact_inner .contact_icon {
    margin: 0 auto;
  }

  .footer .footer_above2 .footer_contact .footer_contact_inner:last-child {
    margin-bottom: 0;
  }

  .funbox2::before {
    right: -30px;
  }

  .testimonial_image {
    text-align: center;
  }

  .testimonial_image .first_img {
    left: 50%;
    transform: translate(-50%);
  }

  .testimonial_image .last_img {
    left: 50%;
    transform: translate(-50%);
  }

  .testimonial2 .testibox2 {
    text-align: center;
  }

  .testimonial2 .testibox2 .testi-top {
    display: unset;
  }

  .testimonial2 .owl-dots {
    bottom: 35px;
    top: unset;
    width: 100%;
  }

  .testimonial2 .owl-dots .owl-dot {
    display: inline-block;
  }

  .testimonial2 .upper_img {
    margin-top: 10px;
  }

  .contact .contact_side_image {
    margin-left: 0;
    text-align: center;
    margin-top: 20px;
  }

  .another_bg_image::before {
    content: none;
  }

  .another_bg_image::after {
    content: none;
  }

  .upper_sm_img::before {
    content: none;
  }

  .page-header .page-header-content {
    padding: 90px 0px 80px 0px;
  }

  .page-header .page-header-content .heading {
    font-size: 40px;
    line-height: 48px;
  }

  .page-header .breadcrumb li {
    font-size: 16px;
  }

  .faq .section_header {
    padding-right: 0;
  }

  .faq .images-faq {
    display: none;
  }

  .testimonial_image .image_color::before {
    content: none;
  }

  .testibox2 p {
    margin-bottom: 15px;
  }

  .side_contact_info .phone {
    font-size: 20px;
  }

  .top_feature .content_inner {
    padding: 0;
  }

  .team_construction_inner > a > img {
    width: 100%;
  }

  .testimonial2 .slick-dots {
    right: 0;
  }

  .another_bg_image {
    padding: 0;
  }

  .top_feature .content_inner h1,
  .blog_details .post_header .post_title {
    font-size: 32px;
    line-height: 40px;
  }

  .pd_tp_sm_110 {
    padding-top: 110px;
  }

  .floating_object {
    display: none;
  }
}

/*-- End max-width: 991px--*/
/*-- max-width: 767px--*/
@media screen and (max-width: 767px) {
  .header .top_bar .top_bar_inner .header_info .free_contact {
    margin-left: 0;
    text-align: center;
  }

  .footer .footer_above {
    text-align: center;
  }

  .footer .widget_title {
    text-align: center;
  }

  .footer .footer_nav {
    display: block;
  }

  .footer .side_footer_social .bottom_social {
    justify-content: center;
  }

  .footer .subscribe {
    margin-right: 0 !important;
  }

  .footer .footer_above2 .footer_widget2 .logo {
    text-align: center;
  }

  .footer .footer_nav {
    text-align: center;
  }

  .footer .footer_widget2 {
    text-align: center;
  }

  .theme_slider_1 .slider::before {
    display: none;
  }

  .blog_post .post_content {
    text-align: center;
    padding: 35px;
  }

  .blog_post .post_content .post_header .post_subtitle {
    justify-content: center;
  }

  .blog .blog_post2 .read_more {
    text-align: center;
  }

  .project_iso .button-group .button {
    font-size: 15px;
    line-height: 26px;
    padding: 6px 16px;
    margin-bottom: 10px;
  }

  .project_iso .grid .element-item {
    width: 47%;
  }

  .project_iso .grid.grid-5 .element-item {
    width: 50%;
  }

  .funbox2::before {
    content: none;
  }

  .funfacts2_bg,
  .funfacts3_bg {
    display: grid;
    background-position: center;
  }

  .funfacts2_bg .funbox2,
  .funfacts3_bg .funbox2 {
    margin-bottom: 20px;
  }

  .funfacts3_bg .funbox2 {
    margin-bottom: 10px;
  }

  .patner_flex {
    flex-flow: row wrap;
  }

  .patner_flex img {
    margin: 0 auto 30px auto;
  }

  .page_header::before {
    content: none !important;
  }

  .page_header::after {
    content: none !important;
  }

  .page_header .page_header_content .heading {
    font-size: 34px;
    line-height: 40px;
  }

  .page_header .breadcrumb li {
    font-size: 15px;
  }

  .footer_widget_padding {
    padding-right: 0;
  }

  .side_contact_info {
    padding: 10px;
  }

  .side_contact_info h4 {
    font-size: 18px;
  }

  .side_contact_info .phone {
    padding: 10px;
    font-size: 16px;
  }

  .side_contact_info .phone span {
    font-size: 13px;
  }

  .side_owl {
    position: unset;
  }

  .patner_2_margin {
    margin-top: 0 !important;
  }

  .another_bg_image {
    padding: 0;
  }

  .service_inner .main_img {
    display: block;
  }

  .service_inner .main_img img {
    width: 60%;
  }

  .contact_us_1 {
    padding: 30px;
  }

  .contact_us_1 .section_header .section_title {
    font-size: 30px;
    line-height: 32px;
  }

  .contact_us_1 .button {
    min-width: 188px;
  }

  .funbox1 .fun_content span {
    font-size: 30px;
  }

  .funbox1 .fun_content p {
    font-size: 14px;
  }

  .inner_posts .inner-post .post_block > a {
    font-size: 12px;
    line-height: 14px;
  }

  .inner_posts .inner-post .post_block h4 {
    font-size: 16px;
    line-height: 18px;
  }

  .inner_posts .inner-post .post_block h4 a {
    font-size: 16px;
    line-height: 18px;
  }

  .blog_details .post_share ul.share_list li a {
    font-size: 8px;
    letter-spacing: 1px;
    padding: 0 5px;
  }
}

/*-- End max-width: 767px--*/
/*-- max-width: 600px--*/
@media screen and (max-width: 600px) {
  .group_image_holder img {
    width: 100%;
  }

  .group_image_holder .sub_img,
  .group_image_holder .sub_img2 {
    position: relative;
    margin: 10px 0px;
    width: 100%;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }

  .expe_box {
    right: 0;
  }

  .experience_content .about_below {
    display: block;
  }

  .experience_content .about_below .about_below_content:first-child {
    margin-bottom: 15px;
  }

  .header .mainnav {
    margin-left: 0;
  }

  .header .phone {
    justify-content: start;
    text-align: left;
    padding-left: 0px;
  }

  .header .phone > i {
    display: none;
  }

  .header .middle_bar_inner {
    flex-flow: row wrap;
    justify-content: center;
    text-align: center;
  }

  .header .middle_bar_inner .header_right_part {
    margin-top: 10px;
  }

  .header .middle_bar_inner .header_right_part #open_search {
    top: 49px;
    right: 0;
    width: 100%;
  }

  .theme_slider_1 .slider .slide_content {
    text-align: center;
  }

  .theme_slider_1 .slider .slide_content .sub_heading {
    font-size: 18px;
  }

  .theme_slider_1 .slider .slide_content .heading {
    font-size: 40px;
    line-height: 44px;
  }

  .theme_slider_1 .slider .slide_content .desc {
    font-size: 20px;
  }

  .theme_slider_1 .slider .slider_button .button {
    margin-right: 0;
  }

  .theme_slider_2 .slider .slide_content .heading {
    font-size: 40px;
    line-height: 44px;
  }

  .funfacts .section_header {
    padding-right: 0;
    text-align: center;
  }

  .project_iso .grid .element-item,
  .project_iso .grid.grid-5 .element-item {
    width: 100%;
  }

  .service_inner .service_content h4 {
    margin-bottom: 15px;
  }

  .service_inner .service_content h6 {
    font-size: 13px;
  }

  .service_inner .service_content a {
    font-size: 15px;
  }

  .owl_service .service_inner_block {
    margin: 0 15px;
  }

  .sm-margin-bottom {
    margin-top: 5px;
  }

  .project_details .post_share .new_share li:first-child {
    margin-right: 5px !important;
  }

  .inner_posts {
    display: block;
  }

  .inner_posts .inner-post {
    display: block;
    text-align: center;
  }

  .inner_posts .inner-post .post_block {
    margin-left: 0;
    margin-bottom: 15px;
  }

  .page_header .page_header_content .heading {
    padding: 0 50px;
  }
}

/*-- max-width: 500px--*/
@media screen and (max-width: 500px) {
  .service_inner .main_img {
    display: none;
  }

  .header .header_social {
    width: 100%;
    justify-content: center;
  }

  .header .header_info {
    width: 100%;
    justify-content: center;
  }

  .header .header_info .schedule {
    flex: none;
  }

  .header .header_info .free_contact a {
    padding: 10px 10px;
  }
}

@media screen and (max-width: 450px) {
  .header .schedule:first-child {
    border: none;
  }

  .theme_slider_1 .slider .slider_button {
    display: block;
    text-align: center;
  }

  .theme_slider_1 .slider .slider_button a {
    width: 100%;
  }

  .patner .patner_image {
    width: 100%;
  }
}
